/** @format */

import React, { useContext, useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import CardMedia from "@mui/material/CardMedia";
import AppHeader from "../AppHeader.react.js";
import { gql, useQuery, useMutation } from "@apollo/client";
import AppSidebar from "../AppSidebar.react.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MyOrderBlankPage from "./MyOrderBlankPage.react.js";
import Typography from "@mui/material/Typography";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from "@mui/material/CircularProgress";
import InfiniteScroll from "react-infinite-scroller";
import { Context } from "../Context/Context.js";
import axios from "axios";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import { Checkbox } from "@mui/material";
import Chip from '@mui/material/Chip';
import CustomSelected from "../CommonComponents/CustomSelected.react.js";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Check from '@mui/icons-material/Check.js';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import TextField from '@mui/material/TextField';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined.js';
import { useNavigate } from "react-router-dom";
import GetCurrencySymbol from "../utils/GetCurrencySymbol.js";
import ProductEditDialog from "../CommonComponents/ProductEditDialog.react.js";
import { Carousel } from 'antd';
import { Spin } from 'antd';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';


const GET_ORDER_Detail = gql`
  query getOrderDetail($orderID: String) {
    getOrderDetail(orderID: $orderID) {
      status
      result {
        orderName
        displayFinancialStatus
        displayFulfillmentStatus
        orderDateMessage
        unfulfilledList {
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          USDPrice
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          orderLink
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
          productCostSchemaID
        }
        fulfilledList{
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
        }
        refundList{
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
          transactionListID
        }
        processingList {
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          orderLink
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
          transactionListID
        }
        processingProductCount
        fulfilledByCartifindList {
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          orderLink
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
        }
        unknownList {
          shopifyProductID
          productSchemaID
          platformProductID
          image
          title
          options
          sku
          productSource
          price
          cost
          costCurrencyCode
          priceCurrencyCode
          available
          quantity
          productWeight
          orderLink
          fulfillmentID
          remainingQuantity
          fulfillmentOrderID
        }
        fulfilledProductCartifindCount
        unfulfilledProductCount
        fulfilledProductCount
        refundProductCount
        unknownProductCount
        orderShippingAddress {
          firstName
          lastName
          phone
          email
          company
          address1
          address2
          city
          province
          country
          countryCodeV2
          zipcode
        }
        shippingList {
          shippingMethod
          fee
        }
        customer {
          firstName
          lastName
          email
          phone
        }
        totalPrice
        totalPriceCurrencyCode
        orderItemsNum
        orderBillingAddress {
          firstName
          lastName
          phone
          email
          company
          address1
          address2
          city
          province
          country
          zipcode
        }
        billingAddressMatchesShippingAddress
        notes
        transactionHistory {
          event
          time
        }
        summary {
          transactionID
          amount
          currency
          time
          trackingNumber
          trackingURL
          transactionStatus
          transactionTooltip
          shipDay
        }
        processingShippingMethod
        fulfilledProductCartifindShippingMethod
        processingAmount
        fulfilledProductCartifindAmount
        processingCurrency
        fulfilledProductCartifindCurrency
        currentOrderHasRefund
        refundedReason
        forcePayable
        sendTrackingEmailToCustomer
        sendSheinOrTemu
        partialRefundIfOutOfStock
        taxRate
        processingCostDetail {
          productCost
          shippingCost
          taxCost
          earned
          totalCost
        }
        fulfilledProductCartifindCostDetail {
          productCost
          shippingCost
          taxCost
          earned
          totalCost
        }
      }
      message
    }
  }
`;

const MARK_AS_FULFILLED = gql`
  mutation markAsFulfilled(
    $fulfillmentOrderID: String
    $fulfillmentID: String
    $quantity: String
  ) {
    markAsFulfilled(
      fulfillmentOrderID: $fulfillmentOrderID
      fulfillmentID: $fulfillmentID
      quantity: $quantity
    ) {
      status
      message
    }
  }
`;

const MARK_AS_FULFILLED_ALL = gql`
  mutation markAsFulfilledAll($needMarkAsFulfilledList: String, $sendEmailProps: String) {
    markAsFulfilledAll(needMarkAsFulfilledList: $needMarkAsFulfilledList, sendEmailProps: $sendEmailProps) {
      status
      message
    }
  }
`;

const MARK_AS_UNFULFILLED_ALL = gql`
  mutation markAsUnFulfilledAll($fulfillmentOrderIDList: [String]) {
    markAsUnFulfilledAll(fulfillmentOrderIDList: $fulfillmentOrderIDList) {
      status
      message
    }
  }
`;

const SAVE_NOTES = gql`
mutation mutation($orderID: String, $notes: String) {
  saveNotes(orderID: $orderID, notes: $notes) {
    status
    message
  }
  }
`;

const GET_ORDER_DETAIL_SHIPPING_METHOD = gql`
query getOrderDetailShippingMethod($productCostSchemaIDs: [String], $country: String, $totalCount: Int) {
  getOrderDetailShippingMethod(productCostSchemaIDs: $productCostSchemaIDs, country: $country, totalCount: $totalCount) {
    fee
    shippingMethod
  }
}
`;

const SAVE_TRANSACTION = gql`
mutation mutation($orderID: String, $transactionID: String, $trackingNumber: String, $trackingURL: String) {
  saveTransaction(orderID: $orderID, transactionID: $transactionID, trackingNumber: $trackingNumber, trackingURL: $trackingURL) {
    status
    message
  }
  }
`;

const SAVE_TRACKURL_BY_FOURPXORDERID = gql`
mutation mutation($trackingNumber: String, $trackingURL: String) {
  saveTrackURLByFourPXOrderID(trackingNumber: $trackingNumber, trackingURL: $trackingURL) {
    status
    message
  }
  }
`;

const REFUND_ORDER = gql`
  mutation refundOrder($orderName: String, $customerName: String, $refundedAmountCurrencySymbol: String, $refundedAmount: String, $transactionListIDList: [String], $refundedReason: String) {
    refundOrder(orderName: $orderName, customerName: $customerName, refundedAmountCurrencySymbol: $refundedAmountCurrencySymbol, refundedAmount: $refundedAmount, transactionListIDList: $transactionListIDList, refundedReason: $refundedReason) {
      status
      message
    }
  }
`;


const REFUND_MISSING_PAYMENT = gql`
  mutation refundMissingPayment($transactionListID: String, $refundedReason: String, $refundedAmount: String, $refundedProductName: String, $orderName: String) {
    refundMissingPayment(transactionListID: $transactionListID, refundedReason: $refundedReason, refundedAmount: $refundedAmount, refundedProductName: $refundedProductName, orderName: $orderName) {
      status
      message
    }
  }
`;

const APP_ONE_TIME_CHARGE = gql`
  mutation appOneTimeCharge($orderName: String, $amount: String, $orderDetailLink: String, $domain: String, $shopifyOrderID: String, $sendEmailProps: String) {
    appOneTimeCharge(orderName: $orderName, amount: $amount, orderDetailLink: $orderDetailLink, domain: $domain, shopifyOrderID: $shopifyOrderID, sendEmailProps: $sendEmailProps){
      status
      message
    }
  }
`;

const SAVE_TRACKING_URL_LIST_AND_TRACKING_NUMBER_LIST = gql`
mutation mutation($transactionID: String, $trackingNumberList: [String], $trackingURLList: [String]) {
  saveTrackingURLListAndTrackingNumberList(transactionID: $transactionID, trackingNumberList: $trackingNumberList, trackingURLList: $trackingURLList) {
    status
    message
  }
  }
`;

const SET_SEND_TRACKING_EMAIL_TO_CUSTOMER_STATUS = gql`
mutation mutation($orderID: String, $sendTrackingEmailToCustomer: String) {
  setSendTrackingEmailToCustomerStatus(orderID: $orderID, sendTrackingEmailToCustomer: $sendTrackingEmailToCustomer) {
    status
    message
  }
  }
`;

const CHECK_ORDER_TRANSACTION_TRACKING_URL_LIST_AND_TRACKING_NUMBER_LIST = gql`
mutation mutation($transactionID: String) {
  checkOrderTransactionTrackingURLListAndTrackingNumberList(transactionID: $transactionID) {
    trackingURLList
    trackingNumberList
  }
  }
`;

const GET_USD_TO_OTHER_CURRENCY_EXCHANGE_QUOTATION = gql`
query getUSDToOtherCurrencyExchangeQuotation {
    getUSDToOtherCurrencyExchangeQuotation
  }
`;

const SET_SEND_SHEIN_OR_TEMU_STATUS = gql`
mutation mutation($orderID: String, $sendSheinOrTemu: Boolean) {
  setSendSheinOrTemuStatus(orderID: $orderID, sendSheinOrTemu: $sendSheinOrTemu) {
    status
    message
  }
  }
`;

const SET_PARTIAL_REFUND_IF_OUT_OF_STOCK_STATUS = gql`
mutation mutation($orderID: String, $partialRefundIfOutOfStock: Boolean) {
  setPartialRefundIfOutOfStockStatus(orderID: $orderID, partialRefundIfOutOfStock: $partialRefundIfOutOfStock) {
    status
    message
  }
  }
`;


const steps = [{
  transaction: 'Select campaign settings',
  date: "2021/3/11 3:40 AM"
}, {
  transaction: 'Create an ad group',
  date: "2021/3/11 3:40 AM"
}, {
  transaction: 'Create an ad',
  date: "2021/3/11 3:40 AM"
}];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgba(189, 189, 189, 1)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgba(189, 189, 189, 1)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "rgba(189, 189, 189, 1)",
    borderLeftWidth: 0,
    display: 'block',
    width: '3px',
    height: '35px',
    background: 'rgba(189, 189, 189, 1)',
    marginLeft: '4.5px',
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: "rgba(189, 189, 189, 1)",
  display: 'flex',
  height: "22px",
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'rgba(189, 189, 189, 1)'
  }),
  '& .QontoStepIcon-completedIcon': {
    width: "12px",
    height: "12px",
    borderRadius: '50%',
    backgroundColor: 'rgba(189, 189, 189, 1)',
  },
  '& .QontoStepIcon-circle': {
    width: "8px",
    height: "8px",
    borderRadius: '50%',
    backgroundColor: '#FFF',
    border: "2px solid rgba(189, 189, 189, 1)"
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ButtonTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={placement ? placement : "bottom"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
    fontSize: "20px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#FFF",
    maxWidth: '260px',
    fontSize: "12px",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)",
  },
}));

const getButtonTooltipLayout = (title) => {
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

const getPayNowButtonTooltipLayout = (shippingCostUnkown, currentOrderHasRefund, refundedReason, orderShippingAddressEncipher, currentOrderHasEmptyImage, currentUnfulfilledListHasUnknownCost) => {
  let title;
  switch (true) {
    case shippingCostUnkown:
      title = "This order cannot be fulfilled because the shipping cost estimated cannot be calculated. ";
      break;
    case currentOrderHasRefund:
      title = refundedReason;
      break;
    case orderShippingAddressEncipher:
      title = "Your order shipping address is invalid, please update it to ensure a valid shipping destination.";
      break;
    case currentOrderHasEmptyImage:
      title = "The current order contains products without pictures.";
      break;
    case currentUnfulfilledListHasUnknownCost:
      title = "Please remove the unknown cost product to fulfill the order."
      break;
    default:
      title = "";
      break;
  }
  return <React.Fragment>
    <Box sx={{
      color: '#FFF',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '150%'
    }}>
      {title}
    </Box>
  </React.Fragment>
}

let shippingOptionsMap = new Map();
let shippingOptions = [{
  option: "No Valid Option",
  value: "shippingMethod",
}];
let shippingOptionsComplete = false;
const OrderSupportCurrency = ["USD", "EUR", "CAD", "AUD",]
let OrderDetailProductCostCurrencyList = [
  {
    option: "USD",
    value: 1
  }
]
let costCurrencyCodeUpper = "USD";
let USDExchangeRateList = {};
sessionStorage.setItem("alreadySendLogPayNow", false);
sessionStorage.setItem("alreadySendLogLoadPage", false);
sessionStorage.setItem("alreadyRetryRequest", false);
export default function MyOrderDetailPage() {
  const { state, setState } = useContext(Context);
  const { sendLog, shopName } = state;
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const isAdmin = queryParams.get("isAdmin");
  const navigate = useNavigate();
  const saveSendLogLoadPage = (loadSuccess, orderID, needNavigate = true) => {
    setTimeout(async () => {
      if (sessionStorage.getItem("alreadySendLogLoadPage") != "true" && sessionStorage.getItem("alreadySendLogLoadPage") != true) {
        sendLog("MY_ORDERS_CARD_DETAIL_LOAD_PAGE", JSON.stringify({ loadSuccess, orderID }));
        sessionStorage.setItem("alreadySendLogLoadPage", true);
      }
      await delay(500);
      if (needNavigate) {
        navigate('/app');
      }
    }, 200)
  }

  if (id == null) {
    saveSendLogLoadPage("0", "gid://shopify/Order/" + id);
    return <></>;
  }
  const {
    loading: USDToCurrenyListLoading,
    data: USDToCurrenyListData,
  } = useQuery(GET_USD_TO_OTHER_CURRENCY_EXCHANGE_QUOTATION, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  if (OrderSupportCurrency != null && OrderDetailProductCostCurrencyList != null && OrderDetailProductCostCurrencyList.length < 2 && USDToCurrenyListLoading === false && USDToCurrenyListData != null && USDToCurrenyListData.getUSDToOtherCurrencyExchangeQuotation != null) {
    USDExchangeRateList = JSON.parse(USDToCurrenyListData.getUSDToOtherCurrencyExchangeQuotation);
    OrderDetailProductCostCurrencyList = OrderSupportCurrency.map(item => {
      if (USDExchangeRateList[item.toLowerCase()] != null) {
        return {
          option: item,
          value: USDExchangeRateList[item.toLowerCase()]
        }
      }
    }).filter(item => item != null);
  }

  const [shippingMethod, setShippingMethod] = useState("No Valid Option");
  const [OpenProductDetailIDList, setOpenProductDetailIDList] = useState([]);
  const [refetchPageNum, setRefetchPageNum] = useState(0);
  const [deleteTimes, setDeleteTimes] = useState(0);
  const [save_notes] = useMutation(SAVE_NOTES);
  const [save_transaction] = useMutation(SAVE_TRANSACTION);
  const [save_tracking_url_list_and_tracking_number_list] = useMutation(SAVE_TRACKING_URL_LIST_AND_TRACKING_NUMBER_LIST);
  const [set_send_tracking_email_to_customer_status] = useMutation(SET_SEND_TRACKING_EMAIL_TO_CUSTOMER_STATUS);
  const [set_send_shein_or_temu_status] = useMutation(SET_SEND_SHEIN_OR_TEMU_STATUS);
  const [set_partial_refund_if_out_of_stock_status] = useMutation(SET_PARTIAL_REFUND_IF_OUT_OF_STOCK_STATUS);
  const [check_order_transaction_tracking_url_list_and_tracking_number_list] = useMutation(CHECK_ORDER_TRANSACTION_TRACKING_URL_LIST_AND_TRACKING_NUMBER_LIST);
  const [save_trackURL_by_fourPXOrderID] = useMutation(SAVE_TRACKURL_BY_FOURPXORDERID);
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);
  const carouselComponent = useRef(null);
  const [orderDetailCheckedCurrencyExchangeRate, setOrderDetailCheckedCurrencyExchangeRate] = useState("1.0");
  const {
    loading: orderDetailLoading,
    data: orderDetailData,
    refetch,
  } = useQuery(GET_ORDER_Detail, {
    variables: {
      orderID: "gid://shopify/Order/" + id,
    },
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: orderDetailShippingMethodLoading,
    data: orderDetailShippingMethodData,
    refetch: orderShippingMethodRefetch,
  } = useQuery(GET_ORDER_DETAIL_SHIPPING_METHOD, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const [currentUnfulfilledList, setCurrentUnfulfilledList] = useState([]);
  const [currentFulfilledList, setCurrentFulfilledList] = useState([]);
  const [currentUnknownList, setCurrentUnknownList] = useState([]);
  const [productCost, setProductCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [yourTotalCost, setYourTotalCost] = useState(0);
  const [earned, setEarned] = useState(0);
  const [productUSDPrice, setProductUSDPrice] = useState(0);
  const [unfulfilledProductCount, setUnfulfilledProductCount] = useState(0);
  const [currentNotes, setCurrentNotes] = useState("");
  const [refundedMissingPaymentTransactionID, setRefundedMissingPaymentTransactionID] = useState("");
  const [changeTrackingListTransactionID, setChangeTrackingListTransactionID] = useState("");
  const [summaryTransactionIDList, setSummaryTransactionIDList] = useState([]);
  const [summaryTrackingURLList, setSummaryTrackingURLList] = useState([]);
  const [summaryTrackingNumberList, setSummaryTrackingNumberList] = useState([]);
  const [updateTrackURLFourPXOrderID, setUpdateTrackURLFourPXOrderID] = useState("");
  const [missingPayment, setMissingPayment] = useState("");
  const [updateTrackURL, setUpdateTrackURL] = useState("");
  const [partiallyRefundedAmount, setPartiallyRefundedAmount] = useState("");
  const [refundedProductName, setRefundedProductName] = useState("");

  const [isLoadingShippingMethod, setIsLoadingShippingMethod] = useState(false);
  const [isOpenCopyCustomer, setIsOpenCopyCustomer] = useState(false);
  const [isOpenCopyShippingAddress, setIsOpenCopyShippingAddress] = useState(false);
  const [isOpenCopyBillingAddress, setIsOpenCopyBillingAddress] = useState(false);
  const [needMarkAsFulfilledList, setNeedMarkAsFulfilledList] = useState([]);
  const [mark_as_fulfilled_all] = useMutation(MARK_AS_FULFILLED_ALL);
  const [mark_as_unfulfilled_all] = useMutation(MARK_AS_UNFULFILLED_ALL);
  const [refund_order] = useMutation(REFUND_ORDER);
  const [refund_missing_payment] = useMutation(REFUND_MISSING_PAYMENT);
  const [app_one_time_charge] = useMutation(APP_ONE_TIME_CHARGE);
  const [mark_as_fulfilled] = useMutation(MARK_AS_FULFILLED);
  const [refetchOrderDetailTimes, setRefetchOrderDetailTimes] = useState(0);
  const [openUnfulfilledAlert, setOpenUnfulfilledAlert] = useState(false);
  const [openRefundAlert, setOpenRefundAlert] = useState(false);
  const [refundListName, setRefundListName] = useState("processingList");
  const [refundType, setRefundType] = useState("processingList");
  const [refundReason, setRefundReason] = useState("the item is out of stock");
  const [currentSelectedFulfillmentOrderIDList, setCurrentSelectedFulfillmentOrderIDList] = useState([]);
  const [shippingCostUnkown, setShippingCostUnkown] = useState(false);
  const [currentOrderHasEmptyImage, setCurrentOrderHasEmptyImage] = useState(false);
  const [currentUnfulfilledListHasUnknownCost, setCurrentUnfulfilledListHasUnknownCost] = useState(false);
  const [currentUnfulfilledListHasAmazonProduct, setCurrentUnfulfilledListHasAmazonProduct] = useState(false);
  const [isShowOrderStatus, setIsShowOrderStatus] = useState(false);
  const [currentOrderStatus, setCurrentOrderStatus] = useState("");
  const [currentOrderStatusDescription, setCurrentOrderStatusDescription] = useState("");
  const [orderStatusStepperWidth, setOrderStatusStepperWidth] = useState("6");
  const [currenctTrackingURL, setCurrenctTrackingURL] = useState("");
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [orderShippingAddressEncipher, setOrderShippingAddressEncipher] = useState(false);
  const [sendTrackingEmailToCustomerStatus, setSendTrackingEmailToCustomerStatus] = useState("1");
  const [sendTrackingEmailToCustomer, setSendTrackingEmailToCustomer] = useState(false);
  const [sendSheinOrTemu, setSendSheinOrTemu] = useState(false);
  const [partialRefundIfOutOfStock, setPartialRefundIfOutOfStock] = useState(false);
  const [checkTrackIsLoading, setCheckTrackIsLoading] = useState(false);
  const [secondRequestLoading, setSecondRequestLoading] = useState(true);
  const [pageLoadingCompleted, setPageLoadingCompleted] = useState(false);


  const saveSendLogPayNow = (allowPayment, orderID) => {
    setTimeout(() => {
      if (sessionStorage.getItem("alreadySendLogPayNow") != "true" && sessionStorage.getItem("alreadySendLogPayNow") != true && pageLoadingCompleted) {
        sendLog("MY_ORDERS_CARD_DETAIL_PAY_NOW_BUTTON_ALLOW_OR_NOT", JSON.stringify({ allowPayment, orderID }));
        sessionStorage.setItem("alreadySendLogPayNow", true);
      }
    }, 200)
  }

  useEffect(() => {
    if (orderDetailLoading === false && orderDetailData != null && orderDetailData.getOrderDetail != null && orderDetailData.getOrderDetail.result != null) {
      const midArr = [];
      setCurrentUnfulfilledList(orderDetailData.getOrderDetail.result.unfulfilledList);
      setCurrentFulfilledList(orderDetailData.getOrderDetail.result.fulfilledList);
      setCurrentUnknownList(orderDetailData.getOrderDetail.result.unknownList);
      setCurrentNotes(orderDetailData.getOrderDetail.result.notes);
      // orderDetailData.getOrderDetail.result.unfulfilledList != null && orderDetailData.getOrderDetail.result.unfulfilledList.map(item => {
      orderDetailData.getOrderDetail.result.processingList != null && orderDetailData.getOrderDetail.result.processingList.map(item => {
        midArr.push({
          fulfillmentOrderID: item.fulfillmentOrderID,
          fulfillmentID: item.fulfillmentID,
          quantity: item.quantity,
          transactionListID: item.transactionListID
        });
      })
      orderDetailData.getOrderDetail.result.unfulfilledList != null && orderDetailData.getOrderDetail.result.unfulfilledList.map(item => {
        const priceCurrencyCodeUpper = item.priceCurrencyCode.toUpperCase();
        if (refetchOrderDetailTimes == 0 && OrderSupportCurrency.includes(priceCurrencyCodeUpper)) {
          costCurrencyCodeUpper = item.priceCurrencyCode.toUpperCase();
          setOrderDetailCheckedCurrencyExchangeRate(USDExchangeRateList[priceCurrencyCodeUpper.toLowerCase()])
          setRefetchOrderDetailTimes(refetchOrderDetailTimes + 1);
        }
      })
      const summaryTransactionIDArr = [];
      let currentOrderStatusIsNull = (currentOrderStatus == null || currentOrderStatus.length == 0);
      orderDetailData.getOrderDetail.result.summary != null && orderDetailData.getOrderDetail.result.summary.map((item, index) => {
        summaryTransactionIDArr.push(item.transactionID);
        if (currentOrderStatusIsNull && item.transactionStatus != "Refunded") {
          currentOrderStatusIsNull = false;
          setCurrentOrderStatus(item.transactionStatus == "Tracking Ready" ? "Shipped" : item.transactionStatus);
          setIsShowOrderStatus(item.transactionStatus == "Tracking Ready" || item.transactionStatus == "Supplier Processing");
          if (item.transactionStatus == "Tracking Ready") {
            setOrderStatusStepperWidth("100");
            setCurrenctTrackingURL(item.trackingURL != null && item.trackingURL != "placeholder" && item.trackingURL.length > 0 ? item.trackingURL : "https://track.4px.com/#/result/0/" + item.trackingNumber);
            setCurrentOrderStatusDescription(`Your order is shipped! Estimated delivery between ${item.shipDay != null ? item.shipDay : "7 to 12"} business days.`);
          } else if (item.transactionStatus == "Supplier Processing") {
            setOrderStatusStepperWidth("67");
            setCurrentOrderStatusDescription("Your order is in hand with our supplier. Supplier is preparing your order for transit to export shipping dock.");
          } else if (item.transactionStatus == "Cartifind Processing") {
            setOrderStatusStepperWidth("6");
            setCurrentOrderStatusDescription("We are currently processing your order, order confirmation will be sent to you within 2 business day");
          }
        }
      })
      setNeedMarkAsFulfilledList(midArr);
      setSummaryTransactionIDList(summaryTransactionIDArr);
      const orderShippingAddress = orderDetailData.getOrderDetail.result.orderShippingAddress;
      if (orderShippingAddress == null ||
        (orderShippingAddress.city != null && orderShippingAddress.city.includes("*")) ||
        (orderShippingAddress.company != null && orderShippingAddress.company.includes("*")) ||
        (orderShippingAddress.country != null && orderShippingAddress.country.includes("*")) ||
        (orderShippingAddress.phone != null && orderShippingAddress.phone.includes("*")) ||
        (orderShippingAddress.province != null && orderShippingAddress.province.includes("*")) ||
        (orderShippingAddress.zipcode != null && orderShippingAddress.zipcode.includes("*"))) {
        setOrderShippingAddressEncipher(true);
      } else {
        setOrderShippingAddressEncipher(false);
      }
      if (orderDetailData.getOrderDetail.result.sendTrackingEmailToCustomer == "0") {
        setSendTrackingEmailToCustomer(false);
      } else if (orderDetailData.getOrderDetail.result.sendTrackingEmailToCustomer != null) {
        setSendTrackingEmailToCustomer(true);
        setSendTrackingEmailToCustomerStatus(orderDetailData.getOrderDetail.result.sendTrackingEmailToCustomer)
      }
      setSendSheinOrTemu(orderDetailData.getOrderDetail.result.sendSheinOrTemu != null ? orderDetailData.getOrderDetail.result.sendSheinOrTemu : false);
      setPartialRefundIfOutOfStock(orderDetailData.getOrderDetail.result.partialRefundIfOutOfStock != null ? orderDetailData.getOrderDetail.result.partialRefundIfOutOfStock : true);
    }
  }, [orderDetailLoading]);

  useEffect(() => {
    if (orderDetailShippingMethodLoading === false && orderDetailShippingMethodData != null && orderDetailShippingMethodData.getOrderDetailShippingMethod != null && orderDetailShippingMethodData.getOrderDetailShippingMethod.length > 0) {
      if (shippingOptions == null || shippingOptions.length === 0) {
        shippingOptions = orderDetailShippingMethodData.getOrderDetailShippingMethod.map(item => {
          shippingOptionsMap.set(
            item.shippingMethod,
            item.fee,
          );
          return {
            option: item.shippingMethod,
            value: item.shippingMethod,
          }
        });
      }
      setShippingMethod(shippingOptions[0].option);
      setShippingCost(Number(shippingOptionsMap.get(shippingOptions[0].option)));
    }
    if (orderDetailShippingMethodLoading === false) {
      setIsLoadingShippingMethod(false);
    }
  }, [orderDetailShippingMethodLoading]);

  useEffect(() => {
    if (currentUnfulfilledList != null && currentUnfulfilledList.length > 0) {
      let allProductCost = 0;
      let allProductPrice = 0;
      let quantity = 0;
      let priceCurrency = 'USD';
      let hasAmazon = false;
      let hasUnknownCost = false;
      let hasEmptyImage = false;
      currentUnfulfilledList.map((item, index) => {
        allProductCost = allProductCost + (String(Number(item.cost)) == "NaN" ? 0 : Number(item.cost)) * (String(Number(item.quantity)) == "NaN" ? 0 : Number(item.quantity));
        allProductPrice = allProductPrice + (String(Number(item.USDPrice)) == "NaN" ? 0 : Number(item.USDPrice)) * (String(Number(item.quantity)) == "NaN" ? 0 : Number(item.quantity));
        quantity = quantity + (String(Number(item.quantity)) == "NaN" ? 0 : Number(item.quantity));
        if (item.productSource == "amazon") {
          hasAmazon = true;
        }
        if (item.cost == "Unknown") {
          hasUnknownCost = true;
        }
        if (item.image == null || item.image.length == 0) {
          hasEmptyImage = true;
        }
      });
      setCurrentUnfulfilledListHasAmazonProduct(hasAmazon);
      setCurrentUnfulfilledListHasUnknownCost(hasUnknownCost);
      setProductCost(allProductCost);
      setTotalCost(Number(shippingCost) + allProductCost);
      setYourTotalCost(Number(shippingCost) + allProductCost);
      setProductUSDPrice(allProductPrice);
      setEarned(allProductPrice - Number(shippingCost) - allProductCost);
      setUnfulfilledProductCount(quantity);
      setCurrentOrderHasEmptyImage(hasEmptyImage);
      setPageLoadingCompleted(true);
    }
  }, [currentUnfulfilledList, shippingCost]);

  useEffect(() => {
    if (shippingOptions != null && shippingOptions.length > 0 && shippingOptions[0].option != "No Valid Option" && shippingMethod == "No Valid Option") {
      setShippingMethod(shippingOptions[0].option);
      setShippingCost(Number(shippingOptionsMap.get(shippingOptions[0].option)));
    }
    if (shippingOptionsComplete) {
      if (shippingOptions == null || (shippingOptions.length === 1 && shippingOptions[0].option == "No Valid Option")) {
        setShippingCostUnkown(true);
      } else {
        setShippingCostUnkown(false);
      }
    }
  }, [shippingOptions]);

  useEffect(() => {
    if (shippingOptionsComplete) {
      if (shippingOptions == null || (shippingOptions.length === 1 && shippingOptions[0].option == "No Valid Option")) {
        setShippingCostUnkown(true);
      } else {
        setShippingCostUnkown(false);
      }
    }
  }, [shippingOptionsComplete]);


  useEffect(() => {
    let carouselTimes = 0;
    const times = setInterval(() => {
      carouselTimes++;
      if (carouselTimes === 3) {
        clearInterval(times);
      } else if (carouselComponent != null && carouselComponent.current != null) {
        carouselComponent.current.next();
      }
    }, 3000);
    return () => {
      clearInterval(times);
    }
  }, []);

  if (isAdmin == null) {
    return <></>;
  }

  const loadingNodes = <Box sx={{ fontSize: '14px', minWidth: '1440px' }}>
    <AppHeader />
    <AppSidebar />
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
      <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', position: 'relative' }}>
        <Box sx={{
          height: "400px",
          marginTop: 10,
          display: "flex",
          flexDirection: 'column',
          alignItems: "center",
        }} >
          <CircularProgress />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            "& .text": {
              height: '40px',
              lineHeight: '40px',
              color: '#666666',
              fontWeight: '400',
              fontSize: '30px',
              textAlign: 'center'
            }
          }}>
            <Carousel dotPosition={"left"} dots={false} ref={carouselComponent}>
              <Box className="text">Syncing order information from Shopify ...</Box>
              <Box className="text">Checking the lowest product price from Cartifind ...</Box>
              <Box className="text">Calculating the latest shipping cost ...</Box>
            </Carousel>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>;
  if (orderDetailLoading === true) {
    return loadingNodes;
  }
  if (orderDetailData == null || orderDetailData.getOrderDetail == null || orderDetailData.getOrderDetail.result == null) {
    if (sessionStorage.getItem("alreadySendLogLoadPage") != "true") {
      sessionStorage.setItem("alreadySendLogLoadPage", "true");
      setTimeout(() => {
        refetch({
          orderID: "gid://shopify/Order/" + id,
        }).then(res => {
          setSecondRequestLoading(false);
        });
      }, 5000)
    } else {
      if (secondRequestLoading) {
        return loadingNodes;
      }
      saveSendLogLoadPage("0", "gid://shopify/Order/" + id);
      return <></>;
    }
    if (secondRequestLoading) {
      return loadingNodes;
    }
  } else {
    saveSendLogLoadPage("1", "gid://shopify/Order/" + id, false);
  }
  const {
    orderName,
    displayFinancialStatus,
    displayFulfillmentStatus,
    orderDateMessage,
    unfulfilledList,
    fulfilledList,
    fulfilledProductCount,
    unknownProductCount,
    orderShippingAddress,
    shippingList,
    customer,
    orderBillingAddress,
    billingAddressMatchesShippingAddress,
    notes,
    summary,
    transactionHistory,
    fulfilledByCartifindList,
    processingList,
    processingProductCount,
    fulfilledProductCartifindCount,
    processingShippingMethod,
    fulfilledProductCartifindShippingMethod,
    processingAmount,
    fulfilledProductCartifindAmount,
    processingCurrency,
    fulfilledProductCartifindCurrency,
    totalPriceCurrencyCode,
    totalPrice,
    orderItemsNum,
    currentOrderHasRefund,
    refundedReason,
    forcePayable,
    refundList,
    refundProductCount,
    taxRate,
    processingCostDetail,
    fulfilledProductCartifindCostDetail,
  } = orderDetailData.getOrderDetail.result;
  if (orderDetailShippingMethodLoading === false && orderDetailShippingMethodData != null && orderDetailShippingMethodData.getOrderDetailShippingMethod != null) {
    shippingOptions = orderDetailShippingMethodData.getOrderDetailShippingMethod.map(item => {
      shippingOptionsMap.set(
        item.shippingMethod,
        item.fee,
      );
      return {
        option: item.shippingMethod,
        value: item.shippingMethod,
      }
    });
  } else if (shippingList != null) {
    shippingOptions = shippingList.map(item => {
      shippingOptionsMap.set(
        item.shippingMethod,
        item.fee,
      );
      return {
        option: item.shippingMethod,
        value: item.shippingMethod,
      }
    });
  }
  shippingOptionsComplete = true;

  const setShippingMethodEffect = (value) => {
    setShippingMethod(value);
    setShippingCost(Number(shippingOptionsMap.get(value)));
  }
  return (
    <>

      <Dialog
        open={openUnfulfilledAlert}
        onClose={() => {
          setOpenUnfulfilledAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "500px",
            height: "240px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '130px',
            fontFamily: "Arial",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: "0.15px",
            color: "#666666",
            position: "relative",
            paddingTop: '20px',
          }}
        >
          <Box sx={{ fontSize: '18px', color: "#333333", fontWeight: "500", }}>Mark As Unfulfilled</Box>
          <Box>This will delete all your existing fulfillments, and mark this order as unfulfilled, so that you can send a fulfillment request to Cartifind. This can not be undone. Do you want to continue ?</Box>
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: '10px 20px',
            "& .button": {
              padding: "6px 10px",
              borderRadius: "10px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              color: "#707070B2",
              backgroundColor: "#e4e4e4",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#e4e4e4",
              },
              marginTop: '10px'
            },
            "& .unfulfilled-button": {
              color: "#FFF",
              backgroundColor: "#FF9711",
              "&:hover": {
                color: "#FFF",
                backgroundColor: "#FF9711",
              },
            },
          }}
        >
          <Button
            className="button"
            onClick={() => {
              setOpenUnfulfilledAlert(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="button unfulfilled-button"
            onClick={() => {
              setOpenUnfulfilledAlert(false);
              setState({
                ...state,
                openSnackbar: true,
                snackbarText:
                  "Mark as unfulfilled action is placed in queue.",
                snackbarState: "WAIT",
              });
              mark_as_unfulfilled_all({
                variables: {
                  fulfillmentOrderIDList: currentSelectedFulfillmentOrderIDList
                },
              }).then((result) => {
                if (result != null && result.data != null && result.data.markAsUnFulfilledAll != null) {
                  if (result.data.markAsUnFulfilledAll.status === true) {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText:
                        "Item is marked as unfulfilled successfully!",
                      snackbarState: "SUCCESS",
                    });
                    refetch({
                      orderID: "gid://shopify/Order/" + id,
                    });
                  } else {
                    setState({
                      ...state,
                      openSnackbar: true,
                      snackbarText:
                        "Something went wrong. Please try again later!",
                      snackbarState: "FAILED",
                    });
                  }
                }
              })
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRefundAlert}
        onClose={() => {
          setOpenRefundAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            width: "474px",
            height: "304px",
            borderRadius: "4px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: '26px',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "160%",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>Reasons for Refund</Box>
        </DialogTitle>
        <DialogContent sx={{
          padding: '0px'
        }}>
          <Box
            sx={{
              height: "174px",
              padding: '8px 16px',
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.87)",
              "& .radio_label_wrap": {
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                fontSize: "16px"
              }
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={refundReason}
                onChange={(e) => { setRefundReason(e.target.value) }}
              >
                <FormControlLabel value="0" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>The item is out of stock</Box>
                </Box>} />
                <FormControlLabel value="1" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>The shipping destination is not supported </Box>
                </Box>} />
                <FormControlLabel value="2" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>The order payment difference is not received </Box>
                </Box>} />
                <FormControlLabel value="3" control={<Radio icon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonUncheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} checkedIcon={<Box sx={{
                  width: '24px',
                  height: '24px',
                  background: 'url("/img/myProduct/RadioButtonCheckedFilled.svg") no-repeat 0 0',
                  backgroundSize: 'contain',
                }} />} />} label={<Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }} className="radio_label_wrap">
                  <Box sx={{ marginRight: "10px" }}>No VIP Shipping </Box>
                </Box>} />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
            gap: "24px",
            height: "36px",
            padding: '8px 16px',
            color: '#707070B2',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            textTransform: "uppercase",

          }}>
            <Box sx={{ cursor: "pointer" }} onClick={() => { setOpenRefundAlert(false); }}>cancel</Box>
            <Box sx={{ color: "#FF9711", cursor: "pointer" }} onClick={() => {
              const transactionListIDList = [];
              switch (refundListName) {
                case "processingList":
                  processingList.map(item => {
                    transactionListIDList.push(item.transactionListID);
                  })
                  break;
                case "refundList":
                  refundList.map(item => {
                    transactionListIDList.push(item.transactionListID);
                  })
                  break;
                default:
                  processingList.map(item => {
                    transactionListIDList.push(item.transactionListID);
                  })
              }
              if (refundType == "refund_missing_payment") {
                refund_missing_payment({
                  variables: {
                    transactionListID: refundedMissingPaymentTransactionID,
                    refundedReason: refundReason,
                    refundedAmount: partiallyRefundedAmount,
                    refundedProductName: refundedProductName,
                    orderName,
                  },
                }).then(result => {
                  if (result != null && result.data != null && result.data.refundMissingPayment != null) {
                    if (result.data.refundMissingPayment.status === true) {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: "Item is refund successfully !",
                        snackbarState: "SUCCESS",
                      });
                      setOpenRefundAlert(false);
                    } else {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: "something wrong!",
                        snackbarState: "FAILED",
                      });
                    }
                  }
                })
              } else {
                refund_order({
                  variables: {
                    orderName,
                    customerName: customer != null ? (customer.firstName + " " + customer.lastName) : "No customer",
                    refundedAmountCurrencySymbol: processingCurrency != null ? GetCurrencySymbol(processingCurrency.toUpperCase()) : "$",
                    refundedAmount: processingAmount,
                    transactionListIDList: transactionListIDList,
                    refundedReason: refundReason,
                  },
                }).then(result => {
                  if (result != null && result.data != null && result.data.refundOrder != null) {
                    if (result.data.refundOrder.status === true) {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: "Item is refund successfully !",
                        snackbarState: "SUCCESS",
                      });
                      setOpenRefundAlert(false);
                    } else {
                      setState({
                        ...state,
                        openSnackbar: true,
                        snackbarText: "something wrong!",
                        snackbarState: "FAILED",
                      });
                    }
                  }
                })
              }
            }}>Refund</Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{ fontSize: '14px', minWidth: '1440px' }} onClick={() => {
        setIsOpenCopyShippingAddress(false);
        setIsOpenCopyCustomer(false);
        setIsOpenCopyBillingAddress(false);
      }}>
        <AppHeader />
        <AppSidebar />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "240px", flexShrink: "0" }}></Box>
          <Box sx={{ display: "flex", width: "calc(100% - 240px)", justifyContent: 'center', position: 'relative' }}>
            {isOpenEdit ? <ProductEditDialog setIsOpenEdit={setIsOpenEdit} /> : <Box sx={{
              maxWidth: '1200px',
              width: "calc(100% - 40px)",
              padding: "16px 20px 20px 20px",
            }}>
              <Box sx={{
                width: '100%',
                height: "80px",
                backgroundColor: "#FFF",
                borderRadius: '4px',
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                padding: "0px 16px",
                boxSizing: 'border-box',
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '32px',
                  gap: '20px',
                  padding: "8px 0px"
                }}>
                  <Box sx={{ fontSize: '24px', fontWeight: '500', color: "rgba(0, 0, 0, 0.87)", cursor: 'pointer', textDecorationLine: "underline", }} onClick={() => {
                    window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/orders/${id}`);
                  }}>{orderName}</Box>
                  <Chip label={displayFinancialStatus} sx={{ fontSize: '14px', color: "rgba(0, 0, 0, 0.87)", backgroundColor: "rgba(0, 0, 0, 0.08)", height: '24px' }} />
                  <Chip label={displayFulfillmentStatus} sx={{ fontSize: '14px', color: "rgba(0, 0, 0, 0.87)", backgroundColor: "rgba(255, 151, 17, 0.80)", height: '24px' }} />
                  {processingProductCount !== 0 ? <Chip label={"PROCESSING"} sx={{ fontSize: '14px', color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#61C454", height: '24px' }} /> : null}
                </Box>
                <Box sx={{ height: '20px', lineHeight: '20px' }}>{orderDateMessage}</Box>
              </Box>
              <Box sx={{
                width: '100%',
                display: "flex",
                gap: '20px',
                marginTop: '20px',
                "& .card": {
                  width: '100%',
                  backgroundColor: "#FFF",
                  borderRadius: '4px',
                  boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                  marginBottom: '20px',
                  boxSizing: 'border-box',
                  padding: "16px 0px"
                }
              }}>
                <Box sx={{
                  minWidth: '768px',
                  width: 'calc(50% - 572px + 768px)',
                }}>
                  {currentOrderStatus != null && currentOrderStatus.length !== 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>
                      <Box sx={{ color: "rgba(255, 151, 17, 1)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box>Order Status: {currentOrderStatus}</Box>
                        <Box sx={{ cursor: "pointer" }} onClick={() => { setIsShowOrderStatus(!isShowOrderStatus); }}>{isShowOrderStatus ? <img src="img/common/icon_Arrow_top.png" width="14px" height="14px" /> : <img src="img/common/icon_Arrow_bottom.png" width="14px" height="14px" />}</Box>
                      </Box>
                      {isShowOrderStatus ? <Box>
                        <Box sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          height: "24px",
                          letterSpacing: '0.17px',
                          marginTop: "10px",
                        }}>{currentOrderStatusDescription}</Box>
                        <Box sx={{
                          width: '100%',
                          height: '4px',
                          borderRadius: '10px',
                          backgroundColor: "#eceef0",
                          margin: "20px 0px 20px",
                          position: "relative",
                        }}>
                          <Box sx={{
                            width: orderStatusStepperWidth + "%",
                            height: '4px',
                            borderRadius: '10px',
                            backgroundColor: "#FF9711",
                            transition: "width 1s ease",
                          }} />
                          <Box sx={{
                            position: "absolute",
                            top: "-3px",
                            left: orderStatusStepperWidth == "100" ? "97%" : orderStatusStepperWidth + "%",
                            width: "8px",
                            height: "8px",
                            backgroundColor: "#FFF",
                            borderRadius: "8px",
                            border: "1px solid #FF9711",
                          }} />
                        </Box>
                        <Box sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontSize: "12px",
                          fontWeight: "400",
                          height: "17px",
                          letterSpacing: '0.17px',
                        }}>
                          <Box>Cartifind Processing</Box>
                          <Box>Confirmed</Box>
                          <Box>Supplier Processing</Box>
                          <Box>Shipped</Box>
                        </Box>
                        <Box sx={{
                          display: "flex",
                          alignItems: 'center',
                          justifyContent: "flex-end",
                        }}>
                          <Box sx={{
                            width: "158px",
                            height: "38px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 151, 17, 1)",
                            fontSize: "14px",
                            fontWeight: "600",
                            letterSpacing: "0.4px",
                            color: "#FFF",
                            cursor: "pointer",
                            marginTop: '10px'
                          }} onClick={() => {
                            if (Number(orderStatusStepperWidth) < 50) {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: "The fulfillment has not shipped yet. The tracking link will become available once its shipped.",
                                snackbarState: "SUCCESS",
                              });
                            } else if (orderStatusStepperWidth == "67") {
                              //
                              const anchorElement = document.getElementById("summary_anchor");
                              if (anchorElement) {
                                const reviewOffsetTop = anchorElement.offsetTop - 100;
                                setCurrentScrollTop(document.documentElement.scrollTop);
                                const timer = setInterval(() => {
                                  setCurrentScrollTop((oldScrollTop) => {
                                    document.documentElement.scrollTop = oldScrollTop;
                                    if (oldScrollTop >= reviewOffsetTop) {
                                      clearInterval(timer);
                                      document.documentElement.scrollTop = reviewOffsetTop;
                                      return oldScrollTop;
                                    }
                                    return oldScrollTop + reviewOffsetTop / 10;
                                  });
                                }, 20);
                              }
                            } else if (orderStatusStepperWidth == "100") {
                              window.open(currenctTrackingURL);
                            }
                          }}>Track</Box>
                        </Box>
                      </Box> : null}
                    </Box>

                  </Box> : null}
                  {processingList != null && processingList.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: '#61C454' }}>Processing ({processingProductCount})</Box>
                      <Box>
                        <ButtonTooltip title={getButtonTooltipLayout("This contains an order processing fee, and VIP shipping fee, which helps to remove Temu package, price tag for certain products, and fulfill when item is out of stock.")} arrow>
                          <Box sx={{
                            width: '400px',
                            height: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '0 10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            fontSize: '16px',
                            background: "#ebebeb"
                          }}>{processingShippingMethod}</Box>
                        </ButtonTooltip>
                        <Box sx={{ color: "#666", fontSize: "12px", marginTop: "5px" }}>This indicates the time of shipped status not including processing time.</Box>
                      </Box>
                    </Box>
                    {
                      processingList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        return <Box key={index}>
                          <Box sx={{ minHeight: '147px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>

                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px', display: 'flex', alignItems: 'center', gap: "5px" }}>Price: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price}{"×" + quantity}</Box></Box>
                                <ButtonTooltip title={getButtonTooltipLayout("This fee may be different from original supplier because it is may be different from source supplier when item is out of stock.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', marginBottom: "30px", display: 'flex', alignItems: 'center', gap: "5px" }}>Cost:<Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}> {GetCurrencySymbol(costCurrencyCode.toUpperCase())}{cost}{"×" + quantity}</Box></Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })
                    }
                    {processingCostDetail != null ? <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: "143%",
                      letterSpacing: '0.17px',
                      height: '72px',
                      padding: "0 32px",
                      textAlign: 'right'
                    }}>
                      <Box sx={{ textAlign: 'left' }}> </Box>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        gap: '48px',
                      }}>
                        <Box>
                          <Box>Product cost: {GetCurrencySymbol(processingCurrency.toUpperCase())}{Number(processingCostDetail.productCost).toFixed(2)}</Box>
                          <Box>Estimated tax: {GetCurrencySymbol(processingCurrency.toUpperCase())}{Number(processingCostDetail.taxCost).toFixed(2)}</Box>
                          <Box>Total cost: {GetCurrencySymbol(processingCurrency.toUpperCase())}{Number(processingCostDetail.totalCost).toFixed(2)}</Box>
                        </Box>
                        <Box>
                          <Box>Shipping cost: {GetCurrencySymbol(processingCurrency.toUpperCase())}{Number(processingCostDetail.shippingCost).toFixed(2)}</Box>
                          <Box sx={{
                            color: '#5CB451',
                          }}>You earned: {GetCurrencySymbol(processingCurrency.toUpperCase())}{(processingCostDetail.earned).toFixed(2)}</Box>
                        </Box>
                      </Box>
                    </Box> : null}
                    <Box>
                      <Box sx={{
                        height: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: "143%",
                        letterSpacing: '0.17px',
                        padding: '16px 32px'
                      }}>
                        <Box>Your Total Cost</Box>
                        <Box>{GetCurrencySymbol(processingCurrency.toUpperCase()) + Number(processingAmount).toFixed(2)}</Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px"
                    }}>
                      <Checkbox
                        checked={sendSheinOrTemu}
                        onChange={(e) => {
                          setSendSheinOrTemu(e.target.checked);
                          set_send_shein_or_temu_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              sendSheinOrTemu: e.target.checked
                            }
                          });
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Send with Shein or Temu package rather than refund when package removal is not supported.</Box>
                    </Box>
                    {unfulfilledList.length > 1 || processingList.length > 1 ? <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px"
                    }}>
                      <Checkbox
                        checked={partialRefundIfOutOfStock}
                        onChange={(e) => {
                          setPartialRefundIfOutOfStock(e.target.checked);
                          set_partial_refund_if_out_of_stock_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              partialRefundIfOutOfStock: e.target.checked
                            }
                          });
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Partial refund fulfillment instead of full order cancellation when some items are out of stock.</Box>
                    </Box> : null}
                    <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px",
                      height: "36px",
                      paddingBottom: "10px"
                    }}>
                      <Checkbox
                        checked={sendTrackingEmailToCustomer}
                        onChange={(e) => {
                          setSendTrackingEmailToCustomer(e.target.checked);
                          set_send_tracking_email_to_customer_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              sendTrackingEmailToCustomer: e.target.checked ? sendTrackingEmailToCustomerStatus : "0"
                            }
                          })
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Send tracking to your customers when items are shipped</Box>
                      <Box sx={{
                        fontSize: '14px',
                        marginLeft: "5px"
                      }}>
                        <CustomSelected
                          width="300px"
                          height="30px"
                          selectedWidth="400px"
                          border="1px solid #999999"
                          selectedItems={[{
                            option: "Send international tracking",
                            value: "1",
                          }, {
                            option: "Send domestic tracking only",
                            value: "2",
                          }]}
                          fontSize="14px"
                          // disabled={!sendTrackingEmailToCustomer}
                          selectedValue={sendTrackingEmailToCustomerStatus}
                          setSelectValue={(value) => {
                            setSendTrackingEmailToCustomerStatus(value);
                            setSendTrackingEmailToCustomer(true);
                            set_send_tracking_email_to_customer_status({
                              variables: {
                                orderID: "gid://shopify/Order/" + id,
                                sendTrackingEmailToCustomer: value
                              }
                            })
                          }}
                        />
                      </Box>

                    </Box>
                    {isAdmin == "1" ? <Box sx={{
                      height: '32px',
                      padding: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '16px',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "24px",
                      letterSpacing: '0.4px',
                      "& div": {
                        width: "126px",
                        height: '24px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                      }
                    }}>
                      <Box sx={{
                        border: "1px solid rgba(0, 0, 0, 0.50)",
                        background: "#FFF",
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          sendLog("MY_ORDERS_CARD_DETAIL_MARK_AS_FULFILLED_CLICK");
                          mark_as_fulfilled_all({
                            variables: {
                              needMarkAsFulfilledList: JSON.stringify(needMarkAsFulfilledList),
                              sendEmailProps: JSON.stringify({
                                customerName: customer != null ? (customer.firstName + " " + customer.lastName) : "No customer",
                                orderName,
                                processingList,
                                totalPayment: processingAmount,
                                currencySymbol: GetCurrencySymbol(processingCurrency.toUpperCase()),
                                taxCost: processingCostDetail.taxCost
                              })
                            },
                          }).then((result) => {
                            // Need to pop up snackbar to tell user this is completed
                            if (result != null && result.data != null && result.data.markAsFulfilledAll != null) {
                              if (result.data.markAsFulfilledAll.status === true) {
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText:
                                    "Item is marked as fulfilled successfully !",
                                  snackbarState: "SUCCESS",
                                });
                              } else {
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText:
                                    "In progress item or item with fulfillment request cannot be marked as fulfilled !",
                                  snackbarState: "FAILED",
                                });
                              }
                            }
                          });
                          setState({
                            ...state,
                            openSnackbar: true,
                            snackbarText:
                              "Mark as fulfilled action is placed in queue.",
                            snackbarState: "WAIT",
                          });
                        }}>Mark As Fulfilled</Box>
                      <Box sx={{
                        border: "1px solid rgba(0, 0, 0, 0.50)",
                        background: "#FFF",
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          setOpenRefundAlert(true);
                          setRefundListName("processingList");
                          setRefundType("refund_order");
                        }}>Refund</Box>
                    </Box> : null}
                  </Box> : null}
                  {currentUnfulfilledList != null && currentUnfulfilledList.length > 0 ? <Box className="card">
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.87)', color: '#E84343' }}>Unfulfilled ({unfulfilledProductCount})</Box>
                      {isLoadingShippingMethod ? <Box sx={{ marginLeft: "10px", marginTop: "10px", height: "20px", color: "#FF97114D" }}><Spin /></Box> :
                        <Box>
                          <ButtonTooltip title={getButtonTooltipLayout("This contains an order processing fee, and VIP shipping fee, which helps to remove Temu package, price tag for certain products, and fulfill when item is out of stock.")} arrow>
                            <Box>
                              <CustomSelected
                                width="400px"
                                height="40px"
                                selectedWidth="400px"
                                border="1px solid #999999"
                                selectedItems={shippingOptions}
                                defaultOption={shippingMethod}
                                selectedValue={shippingMethod}
                                setSelectValue={setShippingMethodEffect}
                              />
                            </Box>
                          </ButtonTooltip>
                          <Box sx={{ color: "#666", fontSize: "12px", marginTop: "5px" }}>This indicates the time of shipped status not including processing time.</Box>
                        </Box>
                      }
                    </Box>
                    {
                      currentUnfulfilledList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          productCostSchemaID,
                          USDPrice,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        const priceNumber = String(Number(price)) == "NaN" ? 0 : Number(price);
                        const costNumber = String(Number(cost)) == "NaN" ? 0 : Number(cost);
                        const USDPriceNumber = String(Number(USDPrice)) == "NaN" ? 0 : Number(USDPrice);
                        return <Box key={index}>
                          <Box sx={{ minHeight: '173px' }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                              borderBottom: available ? "0px solid rgba(0, 0, 0, 0.12)" : "1px solid rgba(0, 0, 0, 0.12)"
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>
                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px', display: "flex", alignItems: 'center', gap: "5px" }}>Price: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price}{"×" + quantity}</Box></Box>
                                <ButtonTooltip title={getButtonTooltipLayout("This fee may be different from original supplier because it is may be different from source supplier when item is out of stock.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', color: Number(costNumber) > Number(USDPriceNumber) ? "#FF3333" : "#000", marginBottom: "30px", display: 'flex', alignItems: "center", gap: "5px" }}>Cost: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(costCurrencyCodeUpper.toUpperCase())}{(cost == "Unknown" ? "Unknown" : (cost * orderDetailCheckedCurrencyExchangeRate).toFixed(2))}{"×" + quantity}</Box></Box>
                                </ButtonTooltip>
                                <Box sx={{
                                  display: 'flex',
                                  width: '75px',
                                  height: '18px',
                                  padding: '6px 16px',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: "1px solid rgba(0, 0, 0, 0.50)",
                                  borderRadius: '4px',
                                  background: "#FFF",
                                  cursor: "pointer"
                                }} onClick={() => {
                                  const midArr = [];
                                  let totalCount = 0;
                                  let Arr = currentUnfulfilledList.filter((item, itemIndex) => {
                                    return index != itemIndex
                                  })
                                  const productCostSchemaIDs = [];
                                  currentUnfulfilledList.map((item, itemIndex) => {
                                    if (index != itemIndex) {
                                      totalCount += Number(item.quantity);
                                      productCostSchemaIDs.push(item.productCostSchemaID);
                                    }
                                  });
                                  setCurrentUnfulfilledList(Arr);

                                  orderShippingMethodRefetch({
                                    productCostSchemaIDs,
                                    country: orderShippingAddress != null ? orderShippingAddress.countryCodeV2 : null,
                                    totalCount,
                                  });
                                  setIsLoadingShippingMethod(true);
                                  setRefetchPageNum(refetchPageNum + 1);
                                }}>Remove</Box>
                              </Box>
                            </Box>
                            {available ? <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: "pointer", color: 'rgba(0, 0, 0, 0.60)', padding: '0 16px 6px 16px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)", height: '20px' }} onClick={() => {
                              const Arr = OpenProductDetailIDList;
                              if (Arr.includes(sku)) {
                                Arr.splice(OpenProductDetailIDList.indexOf(sku), 1)
                              } else {
                                Arr.push(sku);
                              }
                              setOpenProductDetailIDList(Arr);
                              setRefetchPageNum(refetchPageNum + 1);
                            }}>View associated products
                              {OpenProductDetailIDList.includes(sku) ? <img src="img/common/icon_Arrow_top.png" width="14px" height="14px" /> : <img src="img/common/icon_Arrow_bottom.png" width="14px" height="14px" />}
                            </Box> : null}
                          </Box>
                          {OpenProductDetailIDList.includes(sku) && available ? <Box sx={{ height: '140px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)", }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              "&>div": {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '24px'
                              }
                            }}>
                              <Box sx={{ width: '80px', alignItems: 'center', paddingLeft: '10px' }}>Picture</Box>
                              <Box sx={{ width: '148px', alignItems: 'center', padding: '16px' }}>Title</Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Cost</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Shipping Fee</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Store Price</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Profit Markup</Box>
                              </Box>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '84px',
                              "&>div": {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '40px'
                              }
                            }}>
                              <Box sx={{ width: '80px', alignItems: 'center', paddingLeft: '10px' }} onClick={() => {
                                setIsOpenEdit(true);
                                setState({
                                  ...state,
                                  isOpenImportProductDialog: true,
                                  tabValue: 0,
                                });
                                sessionStorage.setItem("isEditing", 'true');
                                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id: productSchemaID, title, thumbnailSource: image, shopifyProductID }));
                              }}>
                                <img src={image} height="60px" />
                              </Box>
                              <Box sx={{
                                width: '148px',
                                lineHeight: '20px',
                                height: '40px',
                                alignItems: 'center',
                                margin: '16px',
                                cursor: 'pointer'
                              }} onClick={() => {
                                setIsOpenEdit(true);
                                setState({
                                  ...state,
                                  isOpenImportProductDialog: true,
                                  tabValue: 0,
                                });
                                sessionStorage.setItem("isEditing", 'true');
                                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id: productSchemaID, title, thumbnailSource: image, shopifyProductID }));
                              }}>
                                <Box sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }}>{title}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{costNumber}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{shippingCost}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{priceNumber}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{(Number(priceNumber) - Number(costNumber) - Number(shippingCost)).toFixed(2) + " " + costCurrencyCode}</Box>
                                <Box sx={{ color: (Number(costNumber) + Number(shippingCost) === 0 ? "0" : (100 * parseFloat((Number(priceNumber) - Number(costNumber) - Number(shippingCost)) / (Number(costNumber) + Number(shippingCost)))).toFixed(2) < 0) ? "rgba(255, 51, 51, 1)" : "rgba(97, 196, 84, 1)" }}>({Number(costNumber) + Number(shippingCost) === 0 ? "0" : (100 * parseFloat((Number(priceNumber) - Number(costNumber) - Number(shippingCost)) / (Number(costNumber) + Number(shippingCost)))).toFixed(2)}%)</Box>
                              </Box>
                            </Box>
                          </Box> : null}
                        </Box>
                      })
                    }
                    <Box>
                      {isLoadingShippingMethod ? <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '72px',
                        paddingRight: "32px",
                      }}><Spin /></Box> :
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          fontSize: "14px",
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: "143%",
                          letterSpacing: '0.17px',
                          height: '72px',
                          padding: "0 32px",
                          textAlign: 'right'
                        }}>
                          <Box sx={{ textAlign: 'left' }}>
                            <CustomSelected
                              width="100px"
                              height="40px"
                              selectedWidth="100px"
                              border="1px solid #999999"
                              selectedItems={OrderDetailProductCostCurrencyList}
                              defaultOption={costCurrencyCodeUpper}
                              selectedValue={orderDetailCheckedCurrencyExchangeRate}
                              setSelectValue={(value) => {
                                setOrderDetailCheckedCurrencyExchangeRate(value);
                              }}
                              setOption={(value) => {
                                costCurrencyCodeUpper = value;
                              }}
                            />
                          </Box>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: '48px',
                          }}>
                            <Box>
                              <Box>Product cost: {GetCurrencySymbol(costCurrencyCodeUpper)}{(productCost * orderDetailCheckedCurrencyExchangeRate).toFixed(2)}</Box>
                              <Box>Estimated tax: {GetCurrencySymbol(costCurrencyCodeUpper)}{(productCost * orderDetailCheckedCurrencyExchangeRate * (taxRate != null ? taxRate : 0.08)).toFixed(2)}</Box>
                              <Box>Total cost: {GetCurrencySymbol(costCurrencyCodeUpper)}{((Number(totalCost) + productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2)}</Box>
                            </Box>
                            <Box>
                              <Box>Shipping cost: {GetCurrencySymbol(costCurrencyCodeUpper)}{(shippingCost * orderDetailCheckedCurrencyExchangeRate).toFixed(2)}</Box>
                              <Box sx={{
                                color: '#5CB451',
                              }}>You earned: {GetCurrencySymbol(costCurrencyCodeUpper)}{((Number(earned) - productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2)}</Box>
                            </Box>
                          </Box>
                        </Box>}
                      <Box sx={{
                        height: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: "143%",
                        letterSpacing: '0.17px',
                        padding: '0 32px'
                      }}>
                        <Box>Your Total Cost</Box>
                        <Box>{isLoadingShippingMethod ? "" : (GetCurrencySymbol(costCurrencyCodeUpper) + Number((Number(yourTotalCost) + productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2))}</Box>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        paddingLeft: "23px"
                      }}>
                        <Checkbox
                          checked={sendSheinOrTemu}
                          onChange={(e) => {
                            setSendSheinOrTemu(e.target.checked);
                            set_send_shein_or_temu_status({
                              variables: {
                                orderID: "gid://shopify/Order/" + id,
                                sendSheinOrTemu: e.target.checked
                              }
                            });
                          }}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                          checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                        />
                        <Box>Send with Shein or Temu package rather than refund when package removal is not supported.</Box>
                      </Box>
                      {unfulfilledList.length > 1 || processingList.length > 1 ? <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        paddingLeft: "23px"
                      }}>
                        <Checkbox
                          checked={partialRefundIfOutOfStock}
                          onChange={(e) => {
                            setPartialRefundIfOutOfStock(e.target.checked);
                            set_partial_refund_if_out_of_stock_status({
                              variables: {
                                orderID: "gid://shopify/Order/" + id,
                                partialRefundIfOutOfStock: e.target.checked
                              }
                            });
                          }}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                          checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                        />
                        <Box>Partial refund fulfillment instead of full order cancellation when some items are out of stock.</Box>
                      </Box> : null}
                      <Box sx={{
                        display: 'flex',
                        alignItems: "center",
                        paddingLeft: "23px"
                      }}>
                        <Checkbox
                          checked={sendTrackingEmailToCustomer}
                          onChange={(e) => {
                            setSendTrackingEmailToCustomer(e.target.checked);
                            set_send_tracking_email_to_customer_status({
                              variables: {
                                orderID: "gid://shopify/Order/" + id,
                                sendTrackingEmailToCustomer: e.target.checked ? sendTrackingEmailToCustomerStatus : "0"
                              }
                            })
                          }}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                          checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                          </Box>}
                        />
                        <Box>Send tracking to your customers when items are shipped</Box>
                        <Box sx={{
                          fontSize: '14px',
                          marginLeft: "5px"
                        }}>
                          <CustomSelected
                            width="300px"
                            height="30px"
                            selectedWidth="400px"
                            border="1px solid #999999"
                            selectedItems={[{
                              option: "Send international tracking",
                              value: "1",
                            }, {
                              option: "Send domestic tracking only",
                              value: "2",
                            }]}
                            fontSize="14px"
                            // disabled={!sendTrackingEmailToCustomer}
                            selectedValue={sendTrackingEmailToCustomerStatus}
                            setSelectValue={(value) => {
                              setSendTrackingEmailToCustomerStatus(value);
                              setSendTrackingEmailToCustomer(true);
                              set_send_tracking_email_to_customer_status({
                                variables: {
                                  orderID: "gid://shopify/Order/" + id,
                                  sendTrackingEmailToCustomer: value
                                }
                              })
                            }}
                          />
                        </Box>

                      </Box>
                      <Box sx={{
                        height: '32px',
                        padding: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px',
                        fontSize: "14px",
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: "24px",
                        letterSpacing: '0.4px',
                        "& div": {
                          width: "126px",
                          height: '24px',
                          padding: '6px 16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '4px',
                          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                        }
                      }}>
                        {isAdmin == "1" ? <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.50)",
                          background: "#FFF",
                          cursor: 'pointer'
                        }}
                          onClick={() => {
                            sendLog("MY_ORDERS_CARD_DETAIL_MARK_AS_FULFILLED_CLICK");
                            const currentUnfulfilledListNeedMarkAsFulfilledList = [];
                            currentUnfulfilledList.map(item => {
                              currentUnfulfilledListNeedMarkAsFulfilledList.push({
                                fulfillmentOrderID: item.fulfillmentOrderID,
                                fulfillmentID: item.fulfillmentID,
                                quantity: item.quantity,
                                transactionListID: item.transactionListID
                              });
                            })
                            mark_as_fulfilled_all({
                              variables: {
                                needMarkAsFulfilledList: JSON.stringify(currentUnfulfilledListNeedMarkAsFulfilledList),
                                sendEmailProps: JSON.stringify({
                                  customerName: customer != null ? (customer.firstName + " " + customer.lastName) : "No customer",
                                  orderName,
                                  processingList: currentUnfulfilledList,
                                  totalPayment: Number((Number(yourTotalCost) + productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                                  currencySymbol: GetCurrencySymbol(costCurrencyCodeUpper),
                                  taxCost: (productCost * orderDetailCheckedCurrencyExchangeRate * (taxRate != null ? taxRate : 0.08)).toFixed(2)
                                })
                              },
                            }).then((result) => {
                              // Need to pop up snackbar to tell user this is completed
                              if (result != null && result.data != null && result.data.markAsFulfilledAll != null) {
                                if (result.data.markAsFulfilledAll.status === true) {
                                  setState({
                                    ...state,
                                    openSnackbar: true,
                                    snackbarText:
                                      "Item is marked as fulfilled successfully !",
                                    snackbarState: "SUCCESS",
                                  });
                                } else {
                                  setState({
                                    ...state,
                                    openSnackbar: true,
                                    snackbarText:
                                      "In progress item or item with fulfillment request cannot be marked as fulfilled !",
                                    snackbarState: "FAILED",
                                  });
                                }
                              }
                            });
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText:
                                "Mark as fulfilled action is placed in queue.",
                              snackbarState: "WAIT",
                            });
                          }}>Mark As Fulfilled</Box> : null}
                        {!forcePayable && (shippingCostUnkown || currentOrderHasRefund || orderShippingAddressEncipher || currentOrderHasEmptyImage || currentUnfulfilledListHasUnknownCost) ? <ButtonTooltip title={getPayNowButtonTooltipLayout(shippingCostUnkown, currentOrderHasRefund, refundedReason, orderShippingAddressEncipher, currentOrderHasEmptyImage, currentUnfulfilledListHasUnknownCost)} arrow><Box trigger={saveSendLogPayNow("0", "gid://shopify/Order/" + id)} sx={{ background: "#ebebeb", color: 'rgba(0, 0, 0, 0.8)', cursor: "pointer" }}>Pay Now</Box></ButtonTooltip> : <Box trigger={saveSendLogPayNow("1", "gid://shopify/Order/" + id)} sx={{ background: isLoadingShippingMethod ? "#ebebeb" : "#61C454", color: 'rgba(255, 255, 255, 1)', cursor: "pointer" }}
                          onClick={() => {
                            if (isLoadingShippingMethod) {
                              return;
                            }
                            const shortList = currentUnfulfilledList.map(
                              (item) => {
                                return {
                                  image: item.image,
                                  productSchemaID: item.productSchemaID,
                                  sku: item.sku,
                                  cost: item.cost == "Unknown" ? 0 : (item.cost * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                                  quantity: item.quantity,
                                  fulfillmentID: item.fulfillmentID,
                                  title: item.title.replace(/%/g, "%25"),
                                }
                              }
                            );
                            const costDetail = {
                              productCost: (productCost * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                              shippingCost: (shippingCost * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                              taxCost: (productCost * orderDetailCheckedCurrencyExchangeRate * (taxRate != null ? taxRate : 0.08)).toFixed(2),
                              earned: ((Number(earned) - productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                              totalCost: ((Number(totalCost) + productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2),
                            }
                            window.open(
                              `https://${window.location.hostname}/oneTimePurchase?amount=${((Number(totalCost) + productCost * (taxRate != null ? taxRate : 0.08)) * orderDetailCheckedCurrencyExchangeRate).toFixed(2)}&shopifyOrderID=${id}&orderName=${orderName.replace(/#/g, "")}&shippingMethod=${shippingMethod}&inventoryDetailList=${encodeURIComponent(JSON.stringify(shortList))}&currencyCode=${costCurrencyCodeUpper}&costDetail=${encodeURIComponent(JSON.stringify(costDetail))}`
                            );
                          }}>
                          Pay Now</Box>}
                      </Box>
                    </Box>

                  </Box> : null}
                  {currentUnknownList != null && currentUnknownList.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.87)', color: '#E84343' }}>Unfulfilled pending in calculation ({unknownProductCount})</Box>
                        <ButtonTooltip title={getButtonTooltipLayout("We are using AI to predict your product cost, and please kindly visit the web page after 2 mins, and the product cost will become available.")} arrow>
                          <Box sx={{
                            width: '15px',
                            height: '15px',
                            background: 'url("img/common/icon_info.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            marginLeft: "10px",
                          }} />
                        </ButtonTooltip>
                      </Box>

                    </Box>
                    {
                      currentUnknownList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          productCostSchemaID,
                          USDPrice,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        const priceNumber = String(Number(price)) == "NaN" ? 0 : Number(price);
                        const costNumber = String(Number(cost)) == "NaN" ? 0 : Number(cost);
                        const USDPriceNumber = String(Number(USDPrice)) == "NaN" ? 0 : Number(USDPrice);
                        return <Box key={index}>
                          <Box sx={{ minHeight: '173px' }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                              borderBottom: available ? "0px solid rgba(0, 0, 0, 0.12)" : "1px solid rgba(0, 0, 0, 0.12)"
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>
                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px' }}>Price: {GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price + "×" + quantity}</Box>
                                <ButtonTooltip title={getButtonTooltipLayout("We are using AI to predict your product cost, and please kindly visit the web page after 2 mins, and the product cost will become available.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', color: Number(costNumber) > Number(USDPriceNumber) ? "#FF3333" : "#000", marginBottom: "30px" }}>Cost: {GetCurrencySymbol(costCurrencyCodeUpper.toUpperCase())}{(cost == "Unknown" ? "Unknown" : (cost * orderDetailCheckedCurrencyExchangeRate).toFixed(2)) + "×" + quantity}</Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                            {available ? <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: "pointer", color: 'rgba(0, 0, 0, 0.60)', padding: '0 16px 6px 16px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)", height: '20px' }} onClick={() => {
                              const Arr = OpenProductDetailIDList;
                              if (Arr.includes(sku)) {
                                Arr.splice(OpenProductDetailIDList.indexOf(sku), 1)
                              } else {
                                Arr.push(sku);
                              }
                              setOpenProductDetailIDList(Arr);
                              setRefetchPageNum(refetchPageNum + 1);
                            }}>View associated products
                              {OpenProductDetailIDList.includes(sku) ? <img src="img/common/icon_Arrow_top.png" width="14px" height="14px" /> : <img src="img/common/icon_Arrow_bottom.png" width="14px" height="14px" />}
                            </Box> : null}
                          </Box>
                          {OpenProductDetailIDList.includes(sku) && available ? <Box sx={{ height: '140px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)", }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              "&>div": {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '24px'
                              }
                            }}>
                              <Box sx={{ width: '80px', alignItems: 'center', paddingLeft: '10px' }}>Picture</Box>
                              <Box sx={{ width: '148px', alignItems: 'center', padding: '16px' }}>Title</Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Cost</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Shipping Fee</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Store Price</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>Profit Markup</Box>
                              </Box>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '84px',
                              "&>div": {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '40px'
                              }
                            }}>
                              <Box sx={{ width: '80px', alignItems: 'center', paddingLeft: '10px' }} onClick={() => {
                                setIsOpenEdit(true);
                                setState({
                                  ...state,
                                  isOpenImportProductDialog: true,
                                  tabValue: 0,
                                });
                                sessionStorage.setItem("isEditing", 'true');
                                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id: productSchemaID, title, thumbnailSource: image, shopifyProductID }));
                              }}>
                                <img src={image} height="60px" />
                              </Box>
                              <Box sx={{
                                width: '148px',
                                lineHeight: '20px',
                                height: '40px',
                                alignItems: 'center',
                                margin: '16px',
                                cursor: 'pointer'
                              }} onClick={() => {
                                setIsOpenEdit(true);
                                setState({
                                  ...state,
                                  isOpenImportProductDialog: true,
                                  tabValue: 0,
                                });
                                sessionStorage.setItem("isEditing", 'true');
                                sessionStorage.setItem("currentClickProduct", JSON.stringify({ id: productSchemaID, title, thumbnailSource: image, shopifyProductID }));
                              }}>
                                <Box sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }}>{title}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{costNumber}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{shippingCost}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{priceNumber}</Box>
                              </Box>
                              <Box sx={{ width: '88px', padding: '16px' }}>
                                <Box>{(Number(priceNumber) - Number(costNumber) - Number(shippingCost)).toFixed(2) + " " + costCurrencyCode}</Box>
                                <Box sx={{ color: (Number(costNumber) + Number(shippingCost) === 0 ? "0" : (100 * parseFloat((Number(priceNumber) - Number(costNumber) - Number(shippingCost)) / (Number(costNumber) + Number(shippingCost)))).toFixed(2) < 0) ? "rgba(255, 51, 51, 1)" : "rgba(97, 196, 84, 1)" }}>({Number(costNumber) + Number(shippingCost) === 0 ? "0" : (100 * parseFloat((Number(priceNumber) - Number(costNumber) - Number(shippingCost)) / (Number(costNumber) + Number(shippingCost)))).toFixed(2)}%)</Box>
                              </Box>
                            </Box>
                          </Box> : null}
                        </Box>
                      })
                    }
                  </Box> : null}
                  {fulfilledByCartifindList != null && fulfilledByCartifindList.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.87)' }}>Fulfilled by Cartifind ({fulfilledProductCartifindCount})</Box>
                        <ButtonTooltip title={getButtonTooltipLayout("This order has already been processed, and fulfilled by Cartifind.")} arrow>
                          <Box sx={{
                            width: '15px',
                            height: '15px',
                            background: 'url("img/common/icon_info.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            marginLeft: "10px",
                          }} />
                        </ButtonTooltip>
                      </Box>
                      <Box>
                        <ButtonTooltip title={getButtonTooltipLayout("This contains an order processing fee, and VIP shipping fee, which helps to remove Temu package, price tag for certain products, and fulfill when item is out of stock.")} arrow>
                          <Box sx={{
                            width: '400px',
                            height: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '0 10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            fontSize: '16px',
                            background: "#ebebeb"
                          }}>{fulfilledProductCartifindShippingMethod}</Box>
                        </ButtonTooltip>
                        <Box sx={{ color: "#666", fontSize: "12px", marginTop: "5px" }}>This indicates the time of shipped status not including processing time.</Box>
                      </Box>
                    </Box>
                    {
                      fulfilledByCartifindList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        return <Box key={index}>
                          <Box sx={{ minHeight: '147px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>

                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px', display: "flex", alignItems: "center", gap: "5px" }}>Price:<Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}> {GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price}{"×" + quantity}</Box></Box>
                                <ButtonTooltip title={getButtonTooltipLayout("This fee may be different from original supplier because it is may be different from source supplier when item is out of stock.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', marginBottom: "30px", display: "flex", alignItems: "center", gap: "5px" }}>Cost: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{cost}{"×" + quantity}</Box></Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })
                    }
                    {fulfilledProductCartifindCostDetail != null ? <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: "143%",
                      letterSpacing: '0.17px',
                      height: '72px',
                      padding: "0 32px",
                      textAlign: 'right'
                    }}>
                      <Box sx={{ textAlign: 'left' }}> </Box>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        gap: '48px',
                      }}>
                        <Box>
                          <Box>Product cost: {GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase())}{Number(fulfilledProductCartifindCostDetail.productCost).toFixed(2)}</Box>
                          <Box>Estimated tax: {GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase())}{Number(fulfilledProductCartifindCostDetail.taxCost).toFixed(2)}</Box>
                          <Box>Total cost: {GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase())}{Number(fulfilledProductCartifindCostDetail.totalCost).toFixed(2)}</Box>
                        </Box>
                        <Box>
                          <Box>Shipping cost: {GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase())}{Number(fulfilledProductCartifindCostDetail.shippingCost).toFixed(2)}</Box>
                          <Box sx={{
                            color: '#5CB451',
                          }}>You earned: {GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase())}{(fulfilledProductCartifindCostDetail.earned).toFixed(2)}</Box>
                        </Box>
                      </Box>
                    </Box> : null}
                    <Box>
                      <Box sx={{
                        height: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: "143%",
                        letterSpacing: '0.17px',
                        padding: '16px 32px'
                      }}>
                        <Box>Your Total Cost</Box>
                        <Box>{GetCurrencySymbol(fulfilledProductCartifindCurrency.toUpperCase()) + Number(fulfilledProductCartifindAmount).toFixed(2)}</Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px"
                    }}>
                      <Checkbox
                        checked={sendSheinOrTemu}
                        onChange={(e) => {
                          setSendSheinOrTemu(e.target.checked);
                          set_send_shein_or_temu_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              sendSheinOrTemu: e.target.checked
                            }
                          });
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Send with Shein or Temu package rather than refund when package removal is not supported.</Box>
                    </Box>
                    {unfulfilledList.length > 1 || processingList.length > 1 ? <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px"
                    }}>
                      <Checkbox
                        checked={partialRefundIfOutOfStock}
                        onChange={(e) => {
                          setPartialRefundIfOutOfStock(e.target.checked);
                          set_partial_refund_if_out_of_stock_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              partialRefundIfOutOfStock: e.target.checked
                            }
                          });
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Partial refund fulfillment instead of full order cancellation when some items are out of stock.</Box>
                    </Box> : null}
                    <Box sx={{
                      display: 'flex',
                      alignItems: "center",
                      paddingLeft: "23px"
                    }}>
                      <Checkbox
                        checked={sendTrackingEmailToCustomer}
                        onChange={(e) => {
                          setSendTrackingEmailToCustomer(e.target.checked);
                          set_send_tracking_email_to_customer_status({
                            variables: {
                              orderID: "gid://shopify/Order/" + id,
                              sendTrackingEmailToCustomer: e.target.checked ? sendTrackingEmailToCustomerStatus : "0"
                            }
                          })
                        }}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        icon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                        checkedIcon={<Box sx={{ width: '18px', height: '18px', background: 'url("img/myProduct/myProduct_icon_checkbox_selected.png") no-repeat 0 0', backgroundSize: 'contain' }}>
                        </Box>}
                      />
                      <Box>Send tracking to your customers when items are shipped</Box>
                      <Box sx={{
                        fontSize: '14px',
                        marginLeft: "5px"
                      }}>
                        <CustomSelected
                          width="300px"
                          height="30px"
                          selectedWidth="400px"
                          border="1px solid #999999"
                          selectedItems={[{
                            option: "Send international tracking",
                            value: "1",
                          }, {
                            option: "Send domestic tracking only",
                            value: "2",
                          }]}
                          fontSize="14px"
                          // disabled={!sendTrackingEmailToCustomer}
                          selectedValue={sendTrackingEmailToCustomerStatus}
                          setSelectValue={(value) => {
                            setSendTrackingEmailToCustomerStatus(value);
                            setSendTrackingEmailToCustomer(true);
                            set_send_tracking_email_to_customer_status({
                              variables: {
                                orderID: "gid://shopify/Order/" + id,
                                sendTrackingEmailToCustomer: value
                              }
                            })
                          }}
                        />
                      </Box>

                    </Box>

                    <Box sx={{
                      height: '32px',
                      padding: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '16px',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "24px",
                      letterSpacing: '0.4px',
                      "& div": {
                        width: "126px",
                        height: '24px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                      }
                    }}>
                      <ButtonTooltip title={getButtonTooltipLayout("Opens a popup to confirm order will be marked as unfulfilled.")} arrow>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.50)",
                          background: "#FFF",
                          cursor: 'pointer'
                        }}
                          onClick={() => {
                            const midArr = [];
                            fulfilledByCartifindList.map(item => {
                              if (!midArr.includes(item.fulfillmentOrderID)) {
                                midArr.push(item.fulfillmentOrderID);
                              }
                            })
                            setCurrentSelectedFulfillmentOrderIDList(midArr);
                            setOpenUnfulfilledAlert(true);
                          }}>Mark As Unfulfilled</Box>
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  {currentFulfilledList != null && currentFulfilledList.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.87)' }}>Fulfilled by you ({fulfilledProductCount})</Box>
                        <ButtonTooltip title={getButtonTooltipLayout("This order has already been marked as fulfilled by you.")} arrow>
                          <Box sx={{
                            width: '15px',
                            height: '15px',
                            background: 'url("img/common/icon_info.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            marginLeft: "10px",
                          }} />
                        </ButtonTooltip>
                      </Box>
                    </Box>
                    {
                      currentFulfilledList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        const priceNumber = String(Number(price)) == "NaN" ? 0 : Number(price);
                        const costNumber = String(Number(cost)) == "NaN" ? 0 : Number(cost);
                        return <Box key={index}>
                          <Box sx={{ minHeight: '147px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>

                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px', display: 'flex', alignItems: "center", gap: "5px" }}>Price: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price}{"×" + quantity}</Box></Box>
                                <ButtonTooltip title={getButtonTooltipLayout("This fee may be different from original supplier because it is may be different from source supplier when item is out of stock.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', marginBottom: "30px", display: 'flex', alignItems: "center", gap: "5px" }}>Cost: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{cost}{"×" + quantity}</Box></Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })
                    }
                    <Box sx={{
                      height: '32px',
                      padding: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '16px',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "24px",
                      letterSpacing: '0.4px',
                      "& div": {
                        width: "126px",
                        height: '24px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                      }
                    }}>
                      <ButtonTooltip title={getButtonTooltipLayout("Opens a popup to confirm order will be marked as unfulfilled.")} arrow>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.50)",
                          background: "#FFF",
                          cursor: 'pointer'
                        }}
                          onClick={() => {
                            const midArr = [];
                            currentFulfilledList.map(item => {
                              if (!midArr.includes(item.fulfillmentOrderID)) {
                                midArr.push(item.fulfillmentOrderID);
                              }
                            })
                            setCurrentSelectedFulfillmentOrderIDList(midArr);
                            setOpenUnfulfilledAlert(true);
                          }}>Mark As Unfulfilled</Box>
                      </ButtonTooltip>
                    </Box>
                  </Box> : null}
                  {refundList != null && refundList.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', height: '72px', padding: '0 16px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ fontSize: "18px", fontWeight: '500', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.87)' }}>Refund by you ({refundProductCount})</Box>
                        <ButtonTooltip title={getButtonTooltipLayout("This order has already been refund by you.")} arrow>
                          <Box sx={{
                            width: '15px',
                            height: '15px',
                            background: 'url("img/common/icon_info.png") no-repeat 0 0',
                            backgroundSize: 'contain',
                            marginLeft: "10px",
                          }} />
                        </ButtonTooltip>
                      </Box>
                    </Box>
                    {
                      refundList.map((
                        {
                          shopifyProductID,
                          productSchemaID,
                          platformProductID,
                          image,
                          title,
                          options,
                          sku,
                          productSource,
                          price,
                          cost,
                          costCurrencyCode,
                          priceCurrencyCode,
                          available,
                          quantity,
                        },
                        index) => {
                        const priceNumber = String(Number(price)) == "NaN" ? 0 : Number(price);
                        const costNumber = String(Number(cost)) == "NaN" ? 0 : Number(cost);
                        return <Box key={index}>
                          <Box sx={{ minHeight: '147px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: "center",
                              padding: '0 16px',
                            }}>
                              <Box sx={image != null && image.length > 0 ? {
                                padding: '16px',
                                width: '100px',
                                height: "100px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              } : {
                                padding: '16px',
                                width: '98px',
                                height: "98px",
                                border: "1px solid #CCC",
                                borderRadius: "5px",
                                "& img": {
                                  borderRadius: "5px"
                                }
                              }}>
                                <img src={image} height="100px" />
                              </Box>
                              <Box sx={{
                                padding: '16px',
                                width: '370px',
                                color: "rgba(0, 0, 0, 0.60)",
                                fontSize: "16px",
                                fontWeight: '400',
                                lineHeight: '143%',
                                letterSpacing: "0.17px",
                              }}>
                                <Box sx={{
                                  color: 'rgba(0,0,0,0.87)',
                                  textDecorationLine: "underline",
                                  cursor: 'pointer',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitLineClamp: 2,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  wordBreak: "break-all",
                                }} onClick={() => {
                                  const beginIndex = shopifyProductID.indexOf('/Product/');
                                  window.open(`https://admin.shopify.com/store/${shopName.replace('.myshopify.com', '')}/products/${shopifyProductID.slice(beginIndex + 9, shopifyProductID.length)}`);
                                }}>{title}</Box>
                                <Box>{options}</Box>
                                <Box>SKU: {sku}</Box>

                                {productSource != null ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer"
                                  }}
                                    onClick={() => {
                                      let url = "";
                                      if (productSource === "aliexpress") {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      } else if (productSource === "amazon") {
                                        url = `https://amazon.com/dp/${platformProductID}`;
                                      } else if (productSource === "temu") {
                                        url = `https://www.temu.com/g-${platformProductID}.html`;
                                      } else if (productSource === "etsy") {
                                        url = `https://www.etsy.com/`;
                                      } else if (productSource === "1688") {
                                        url = `https://detail.1688.com/offer/${platformProductID}.html`;
                                      } else if (productSource === "shein") {
                                        url = `https://www.shein.com/shein-p-${platformProductID}.html`;
                                      } else if (productSource === "banggood") {
                                        url = `https://www.banggood.com/banggood-p-${platformProductID}.html`;
                                      } else {
                                        url = `https://www.aliexpress.com/item/${platformProductID}.html`;
                                      }
                                      window.open(url);
                                    }}>Source</Box>
                                  <Box sx={{
                                    width: '12px',
                                    height: '12px',
                                    background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                    backgroundSize: 'contain',
                                    marginLeft: "3px",
                                    cursor: 'pointer'
                                  }} />
                                </Box> : null}
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                width: '170px',
                                padding: '16px',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                alignSelf: 'stretch',
                              }}>
                                <Box sx={{ height: '20px', lineHeight: '20px', display: 'flex', alignItems: 'center', gap: "5px" }}>Price: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(priceCurrencyCode.toUpperCase())}{price}{"×" + quantity}</Box></Box>
                                <ButtonTooltip title={getButtonTooltipLayout("This fee may be different from original supplier because it is may be different from source supplier when item is out of stock.")} arrow>
                                  <Box sx={{ height: '20px', lineHeight: '20px', marginBottom: "30px", display: 'flex', alignItems: 'center', gap: "5px" }}>Cost: <Box sx={{ fontWeight: quantity == 1 ? "500" : "600" }}>{GetCurrencySymbol(costCurrencyCode.toUpperCase())}{cost}{"×" + quantity}</Box></Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })
                    }
                    {isAdmin == "1" ? <Box sx={{
                      height: '32px',
                      padding: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '16px',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "24px",
                      letterSpacing: '0.4px',
                      "& div": {
                        width: "126px",
                        height: '24px',
                        padding: '6px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                      }
                    }}>
                      <Box sx={{
                        border: "1px solid rgba(0, 0, 0, 0.50)",
                        background: "#FFF",
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          setOpenRefundAlert(true);
                          setRefundListName("refundList");
                          setRefundType("refund_order");
                        }}>Refund</Box>
                    </Box> : null}
                  </Box> : null}
                  <Box className="card" sx={{ padding: "0px!important" }}>

                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }} id="summary_anchor">
                      <ButtonTooltip title={getButtonTooltipLayout("Fulfillment Summary contains a list of payment transaction, and tracking number if order is fulfilled or processed by Cartifind.")} arrow>
                        <Box sx={{ width: '200px' }}>Fulfillment Summary</Box>
                      </ButtonTooltip>
                    </Box>

                    <Box sx={{
                      height: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: "143%",
                      padding: '16px 24px',
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ width: "136px", }}>Transaction ID </Box>
                      <Box sx={{ width: "120px" }}>Amount</Box>
                      <Box sx={{ width: "160px" }}>Time</Box>
                      <Box sx={{ width: "160px" }}>Tracking Number</Box>
                      <Box sx={{ width: "160px" }}>Transaction Status</Box>
                    </Box>
                    {
                      summary.map((item, index) => {
                        return <Box sx={{
                          height: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: "14px",
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: "143%",
                          padding: '16px 24px',
                          letterSpacing: '0.17px',
                        }} key={index}>
                          <Box sx={{ width: "136px" }}>{item.transactionID}</Box>
                          <Box sx={{ width: "120px" }}>{item.amount + " " + item.currency}</Box>
                          <Box sx={{ width: "160px" }}>{item.time}</Box>
                          <Box sx={{ width: "160px" }}>
                            <ButtonTooltip title={getButtonTooltipLayout("This is the order number, and links to international tracking. It may take some times for tracking to become available due to order processing, and China domestic shipping.")} arrow>
                              <Box sx={{ width: "140px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item.trackingNumber == null ? "Pending" : item.trackingNumber}</Box>
                            </ButtonTooltip>
                          </Box>
                          <ButtonTooltip title={getButtonTooltipLayout(item.transactionTooltip)} arrow>
                            <Box sx={item.transactionStatus == "Tracking Ready" ? { width: "160px", cursor: "pointer", textDecorationLine: "underline", display: 'flex', alignItems: "center" } : { width: "160px" }} >
                              <Box onClick={() => {
                                if (item.transactionStatus == "Tracking Ready") {
                                  window.open(item.trackingURL != null && item.trackingURL != "placeholder" && item.trackingURL.length > 0 ? item.trackingURL : "https://track.4px.com/#/result/0/" + item.trackingNumber);
                                }
                              }}>{item.transactionStatus}</Box>
                              {item.transactionStatus == "Tracking Ready" ? <Box sx={{
                                width: '12px',
                                height: '12px',
                                background: 'url("img/myProduct/myProduct_icon_skip.png") no-repeat 0 0',
                                backgroundSize: 'contain',
                                marginLeft: "3px",
                                cursor: 'pointer'
                              }} /> : null}
                            </Box>
                          </ButtonTooltip>
                        </Box>
                      })
                    }
                  </Box>

                  {isAdmin == "1" ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>
                      Pay the Missing Payment
                    </Box>
                    <Box sx={{
                      fontSize: "14px",
                      fontStyle: 'normal',
                      lineHeight: "143%",
                      padding: '16px 24px',
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Amount:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Tracking-Number"
                            placeholder="Enter the amount to be paid"
                            value={missingPayment}
                            onChange={(e) => {
                              const value = e.target.value
                              let str = value.replace(/[^\d^\.]+/g, '');
                              const len1 = str.substr(0, 1);
                              const len2 = str.substr(1, 1);

                              //如果第一位是0，第二位不是点，就用数字把点替换掉
                              if (str.length > 1 && len1 == 0 && len2 != '.') {
                                str = str.substr(1, 1);
                              }

                              //第一位不能是.
                              if (len1 == '.') {
                                str = '';
                              }

                              //限制只能输入一个小数点
                              if (str.indexOf(".") != -1) {
                                const str_ = str.substr(str.indexOf(".") + 1);
                                //限制只能输入一个小数点
                                if (str_.indexOf(".") != -1) {
                                  str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
                                }
                              }
                              setMissingPayment(str);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                        }} onClick={() => {
                          if (missingPayment == null || missingPayment.length === 0) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Tracking Number is null',
                              snackbarState: 'FAILED',
                            });
                            return;
                          }
                          app_one_time_charge({
                            variables: {
                              orderName,
                              amount: missingPayment,
                              orderDetailLink: `https://cartifind.com/orderDetail?id=${id}`,
                              domain: window.location.hostname,
                              shopifyOrderID: id,
                              sendEmailProps: JSON.stringify({
                                customerName: customer != null ? (customer.firstName + " " + customer.lastName) : "No customer",
                                orderName,
                                processingList,
                                totalPayment: processingAmount,
                                currencySymbol: GetCurrencySymbol(processingCurrency.toUpperCase())
                              })
                            }
                          }).then((result) => {
                            if (result != null && result.data != null && result.data.appOneTimeCharge != null && result.data.appOneTimeCharge.status) {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'send success !',
                                snackbarState: 'SUCCESS',
                              });
                            } else {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'fail to send !',
                                snackbarState: 'FAILED',
                              });
                            }
                          })
                        }}>Send Email</Box>
                      </Box>
                    </Box>
                  </Box> : null}
                  {isAdmin == "1" ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>
                      <Box>Refunded Missing Payment by Transaction ID</Box>
                    </Box>
                    <Box sx={{
                      fontSize: "14px",
                      fontStyle: 'normal',
                      lineHeight: "143%",
                      padding: '16px 24px',
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Transaction ID:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Transaction-ID"
                            placeholder="Enter order Transaction ID"
                            value={refundedMissingPaymentTransactionID}
                            onChange={(e) => {
                              setRefundedMissingPaymentTransactionID(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Refund Amount:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Tracking-Number"
                            placeholder="Enter the amount to be paid"
                            value={partiallyRefundedAmount}
                            onChange={(e) => {
                              const value = e.target.value
                              let str = value.replace(/[^\d^\.]+/g, '');
                              const len1 = str.substr(0, 1);
                              const len2 = str.substr(1, 1);

                              //如果第一位是0，第二位不是点，就用数字把点替换掉
                              if (str.length > 1 && len1 == 0 && len2 != '.') {
                                str = str.substr(1, 1);
                              }

                              //第一位不能是.
                              if (len1 == '.') {
                                str = '';
                              }

                              //限制只能输入一个小数点
                              if (str.indexOf(".") != -1) {
                                const str_ = str.substr(str.indexOf(".") + 1);
                                //限制只能输入一个小数点
                                if (str_.indexOf(".") != -1) {
                                  str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
                                }
                              }
                              setPartiallyRefundedAmount(str);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Refund Produt Name:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Tracking-Number"
                            placeholder="Enter the amount to be paid"
                            value={refundedProductName}
                            onChange={(e) => {
                              setRefundedProductName(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                        }} onClick={() => {
                          if (refundedMissingPaymentTransactionID == null || refundedMissingPaymentTransactionID.length == 0) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Please check that the TransactionID is correct !',
                              snackbarState: 'FAILED',
                            });
                            return;
                          }
                          setOpenRefundAlert(true);
                          setRefundType("refund_missing_payment");

                        }}>REFUND</Box>
                      </Box>
                    </Box>
                  </Box> : null}
                  {isAdmin == "1" ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>
                      <Box sx={{ wordSpacing: "5px" }}>Change TrackingURLList And TrackingNumberList</Box>
                    </Box>
                    <Box sx={{
                      fontSize: "14px",
                      fontStyle: 'normal',
                      lineHeight: "143%",
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ display: "flex", alignItems: 'center', padding: "0px 24px 10px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Transaction ID:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Transaction-ID"
                            placeholder="Enter order Transaction ID"
                            value={changeTrackingListTransactionID}
                            onChange={(e) => {
                              setChangeTrackingListTransactionID(e.target.value);
                            }}
                          />

                        </Box>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)',
                          marginLeft: "20px"
                        }} onClick={() => {
                          setCheckTrackIsLoading(true);
                          check_order_transaction_tracking_url_list_and_tracking_number_list({
                            variables: {
                              transactionID: changeTrackingListTransactionID,
                            }
                          }).then(result => {
                            if (result != null && result.data != null && result.data.checkOrderTransactionTrackingURLListAndTrackingNumberList != null) {
                              const trackingData = result.data.checkOrderTransactionTrackingURLListAndTrackingNumberList;
                              setSummaryTrackingURLList(trackingData.trackingURLList != null && trackingData.trackingURLList.length > 0 ? trackingData.trackingURLList : [""]);
                              setSummaryTrackingNumberList(trackingData.trackingNumberList != null && trackingData.trackingNumberList.length > 0 ? trackingData.trackingNumberList : [""]);
                            } else {
                              setSummaryTrackingURLList([]);
                              setSummaryTrackingNumberList([]);
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'Please check that the TransactionID is correct !',
                                snackbarState: 'FAILED',
                              });
                            }
                            setCheckTrackIsLoading(false);
                          })
                        }}>CHECK</Box>
                      </Box>
                      {checkTrackIsLoading ? <Box sx={{ height: "50px", display: 'flex', alignItems: "center", justifyContent: 'center' }}><Spin /></Box> : summaryTrackingURLList.map((item, index) => {
                        return <TransactionTrackingURLAndTrackingNumberInput
                          key={index}
                          index={index}
                          trackingNumber={summaryTrackingNumberList != null && summaryTrackingNumberList.length > index ? summaryTrackingNumberList[index] : ""}
                          trackingURL={item}
                          summaryTrackingURLList={summaryTrackingURLList}
                          summaryTrackingNumberList={summaryTrackingNumberList}
                          setSummaryTrackingURLList={setSummaryTrackingURLList}
                          setSummaryTrackingNumberList={setSummaryTrackingNumberList}
                          refetchPage={() => { setRefetchPageNum(refetchPageNum + 1) }}
                          onClose={() => {
                            const midSummaryTrackingURLList = [...summaryTrackingURLList];
                            const midSummaryTrackingNumberList = [...summaryTrackingNumberList];
                            midSummaryTrackingURLList.splice(index, 1);
                            midSummaryTrackingNumberList.splice(index, 1);
                            setSummaryTrackingURLList(midSummaryTrackingURLList);
                            setSummaryTrackingNumberList(midSummaryTrackingNumberList);
                            setRefetchPageNum(refetchPageNum + 1);
                            setDeleteTimes(deleteTimes + 1);
                          }}
                          deleteTimes={deleteTimes}
                        />
                      })}
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: '16px 24px',
                        justifyContent: "flex-end",
                      }}>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                        }} onClick={() => {
                          setSummaryTrackingURLList([...summaryTrackingURLList, ""]);
                          setSummaryTrackingNumberList([...summaryTrackingNumberList, ""]);
                          setRefetchPageNum(refetchPageNum + 1);
                        }}>ADD ITEM</Box>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                        }} onClick={() => {
                          let hasEmpty = false;
                          if (changeTrackingListTransactionID != null && summaryTrackingNumberList != null && summaryTrackingURLList != null) {
                            summaryTrackingNumberList.map(item => {
                              if (item == null || item == "placeholder" || item.length == 0) {
                                hasEmpty = true;
                              }
                            })

                            if (hasEmpty) {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'Tracking URL or Tracking Number is empty',
                                snackbarState: 'FAILED',
                              });
                              return;
                            }
                            save_tracking_url_list_and_tracking_number_list({
                              variables: {
                                transactionID: changeTrackingListTransactionID,
                                trackingNumberList: summaryTrackingNumberList,
                                trackingURLList: summaryTrackingURLList,
                              }
                            }).then((result) => {
                              if (result != null && result.data != null && result.data.saveTrackingURLListAndTrackingNumberList != null && result.data.saveTrackingURLListAndTrackingNumberList.status) {
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText: 'save success !',
                                  snackbarState: 'SUCCESS',
                                });
                              } else {
                                setState({
                                  ...state,
                                  openSnackbar: true,
                                  snackbarText: 'fail to save !',
                                  snackbarState: 'FAILED',
                                });
                              }
                            })
                          } else {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Check Transaction ID is correct',
                              snackbarState: 'FAILED',
                            });
                          }
                        }}>SAVE</Box>
                      </Box>
                    </Box>
                  </Box> : null}
                  {isAdmin == "1" ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>
                      Update Tracking URL By Tracking Number
                    </Box>
                    <Box sx={{
                      fontSize: "14px",
                      fontStyle: 'normal',
                      lineHeight: "143%",
                      padding: '16px 24px',
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Tracking Number:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-Tracking-Number"
                            placeholder="Enter order Tracking Number"
                            value={updateTrackURLFourPXOrderID}
                            onChange={(e) => {
                              setUpdateTrackURLFourPXOrderID(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "20px" }}>
                        <Box sx={{ width: "200px", fontWeight: '700', }}>Tracking URL:</Box>
                        <Box sx={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: '4px'
                        }}>
                          <TextField
                            sx={{
                              paddingRight: "0",
                              backgroundColor: "#fff",
                              border: 'none',
                              fontSize: "14px",
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: "24px",
                              letterSpacing: '0.15px',
                              color: "rgba(0, 0, 0, 0.6)",
                              borderColor: "transparent",
                              borderRadius: '10px',
                              width: "300px",
                              "& fieldset": {
                                borderColor: "transparent!important",
                                borderWidth: '1px',
                                "&.Mui-focused": {
                                  borderColor: "transparent!important",
                                  borderWidth: '1px',
                                },
                              },
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                padding: "0px",
                                fontSize: '14px'
                              },
                              "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                                padding: "6px 10px",
                                fontSize: '14px'
                              }
                            }}
                            id="outlined-multiline-static-trackingURL"
                            placeholder="Enter order trackingURL"
                            value={updateTrackURL}
                            onChange={(e) => {
                              setUpdateTrackURL(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}>
                        <Box sx={{
                          padding: "6px 10px",
                          borderRadius: "4px",
                          fontFamily: "Arial",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: "#FFF",
                          backgroundColor: "#FF9711",
                          boxShadow: '0px 3px 4px 1px rgba(0, 0, 0, 0.25)'
                        }} onClick={() => {
                          if (updateTrackURLFourPXOrderID == null || updateTrackURLFourPXOrderID.length === 0) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Tracking Number is null',
                              snackbarState: 'FAILED',
                            });
                            return;
                          }
                          if (updateTrackURL == null || updateTrackURL.length === 0) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'Tracking URL is null',
                              snackbarState: 'FAILED',
                            });
                            return;
                          }
                          save_trackURL_by_fourPXOrderID({
                            variables: {
                              trackingNumber: updateTrackURLFourPXOrderID,
                              trackingURL: updateTrackURL,
                            }
                          }).then((result) => {
                            if (result != null && result.data != null && result.data.saveTrackURLByFourPXOrderID != null && result.data.saveTrackURLByFourPXOrderID.status) {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'save success !',
                                snackbarState: 'SUCCESS',
                              });
                            } else {
                              setState({
                                ...state,
                                openSnackbar: true,
                                snackbarText: 'fail to save !',
                                snackbarState: 'FAILED',
                              });
                            }
                          })
                        }}>SAVE</Box>
                      </Box>
                    </Box>
                  </Box> : null}
                  <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>Paid by Customer</Box>
                    <Box sx={{
                      height: '56px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: "14px",
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: "24px",
                      padding: '16px 24px',
                      letterSpacing: '0.17px',
                    }}>
                      <Box sx={{ width: "156px", height: '24px' }}>Order items </Box>
                      <Box sx={{ width: "180px", height: '24px' }}>{orderItemsNum} items </Box>
                      <Box sx={{ width: "200px", height: '24px' }}>total </Box>
                      <Box sx={{ width: "200px", height: '24px' }}>{GetCurrencySymbol(totalPriceCurrencyCode)}{Number(totalPrice).toFixed(2)}</Box>
                    </Box>
                  </Box>
                  {transactionHistory != null && transactionHistory.length > 0 ? <Box className="card" sx={{ padding: "0px!important" }}>
                    <Box sx={{
                      height: '32px',
                      padding: '16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    }}>Cartifind Transaction History</Box>
                    <Box sx={{
                      padding: '16px 24px',
                      "& .MuiStepConnector-root": {
                        marginLeft: "0px"
                      },
                      "& .MuiStepLabel-iconContainer": {
                        paddingRight: '16px'
                      },
                      "& .MuiStep-root .MuiStepLabel-root": {
                        padding: '0px'
                      }
                    }}>
                      <Stepper activeStep={9} orientation="vertical" connector={<QontoConnector />}>
                        {transactionHistory.map((step, index) => {
                          if (step != null) {
                            return <Step key={index}>
                              <StepLabel StepIconComponent={QontoStepIcon}>
                                <Box sx={{
                                  minHeight: '35px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  fontSize: "16px",
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: "150%",
                                  letterSpacing: '0.15px',
                                }}>
                                  <Box sx={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    maxWidth: "450px"
                                  }}>{step.event}</Box>
                                  <Box sx={{
                                    color: "rgba(0, 0, 0, 0.6)"
                                  }}>{step.time}</Box>
                                </Box>
                              </StepLabel>
                            </Step>
                          }
                        })}
                      </Stepper>
                    </Box>
                  </Box> : null}
                </Box>
                <Box sx={{
                  minWidth: '376px',
                  width: 'calc(50% - 572px + 376px)',
                }}>
                  <Box className="card">
                    <Box sx={{
                      height: '32px',
                      padding: '16px 16px 16px 24px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px'
                    }}>Notes</Box>
                    {/* <Box sx={{
                    height: '16px',
                    padding: '16px 16px 16px 24px',
                    fontSize: "14px",
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: "143%",
                    letterSpacing: '0.17px',
                    color: 'rgba(0, 0, 0, 0.60)'
                  }}>{notes == null || notes.length === 0 ? "No notes from customer" : notes}</Box> */}
                    <Box sx={{
                      margin: '8px 24px',
                      width: 'calc(100% - 50px)',
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: '4px'
                    }}>
                      <TextField
                        sx={{
                          width: '100%',
                          paddingRight: "0",
                          backgroundColor: "#fff",
                          border: 'none',
                          fontSize: "14px",
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: "24px",
                          letterSpacing: '0.15px',
                          color: "rgba(0, 0, 0, 0.6)",
                          borderColor: "transparent",
                          borderRadius: '10px',
                          "& fieldset": {
                            borderColor: "transparent!important",
                            borderWidth: '1px',
                            "&.Mui-focused": {
                              borderColor: "transparent!important",
                              borderWidth: '1px',
                            },
                          },
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            padding: "8px 12px",
                            fontSize: '14px'
                          }
                        }}
                        id="outlined-multiline-static"
                        multiline
                        rows={8}
                        placeholder="Add your notes to Cartifind here"
                        value={currentNotes}
                        onChange={(e) => {
                          setCurrentNotes(e.target.value);
                        }}
                      />
                    </Box>
                    <Box sx={{
                      height: '14px',
                      padding: '16px 16px 16px 24px',
                      fontSize: "16px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "22px",
                      letterSpacing: '0.46px',
                      color: "#2196F3",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      cusor: "pointer"
                    }}>
                      <Box sx={{
                        cursor: "pointer"
                      }} onClick={() => {
                        if (currentNotes == null || currentNotes.length === 0) {
                          return;
                        }
                        save_notes({
                          variables: {
                            orderID: "gid://shopify/Order/" + id,
                            notes: currentNotes
                          }
                        }).then((result) => {
                          if (result != null && result.data != null && result.data.saveNotes != null && result.data.saveNotes.status) {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'save success !',
                              snackbarState: 'SUCCESS',
                            });
                          } else {
                            setState({
                              ...state,
                              openSnackbar: true,
                              snackbarText: 'fail to save !',
                              snackbarState: 'FAILED',
                            });
                          }
                        })
                      }}>Save</Box>
                    </Box>
                  </Box>
                  <Box className="card" sx={{
                    wordBreak: 'break-all',
                    padding: '0px 8px!important',
                    "&>div": {
                      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
                      padding: '16px',
                    },
                    "& .title": {
                      height: '32px',
                      fontSize: "18px",
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: "160%",
                      letterSpacing: '0.15px',
                    },
                    "& .item": {
                      minHeight: '24px',
                      fontSize: "16px",
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: "150%",
                      letterSpacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.89)',
                      marginTop: '8px'
                    }
                  }}>
                    <Box>
                      <Box className="title">Customer</Box>
                      <Box className="item">{customer != null ? (customer.firstName + " " + customer.lastName) : "No customer"}</Box>
                    </Box>
                    <Box>
                      <Box className="title">Contact information</Box>
                      <Box sx={{ display: "flex" }} onClick={(e) => {
                        e.stopPropagation();
                      }}>
                        <Box sx={{ flex: '1' }}>
                          {customer != null ? <Box className="item" sx={{
                            color: "rgba(30, 122, 194, 1)!important",
                          }}>{customer.email}</Box> : <Box className="item" sx={{ color: '(51, 51, 51, 0.70)' }}>No email</Box>}
                          {customer != null ? <Box className="item">{customer.phone == null ? "No phone number" : customer.phone}</Box> : <Box className="item" sx={{ color: '(51, 51, 51, 0.70)' }}>No phone number</Box>}
                        </Box>
                        {customer != null ?
                          <Tooltip title={<Box sx={{ fontSize: '14px' }}>copied</Box>} arrow
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                fontSize: '14px'
                              },
                              "& .MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementBottom": {
                                fontSize: '14px'
                              },
                              fontSize: '14px'
                            }}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => { setIsOpenCopyCustomer(false) }}
                            open={isOpenCopyCustomer}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener>
                            <Box sx={{ height: "30px" }}>
                              <CopyToClipboard text={customer.firstName + " " + customer.lastName + ", " + customer.email + ", " + (customer.phone == null ? "" : customer.phone)}>
                                <ContentCopyOutlinedIcon sx={{ width: '24px', height: '24px', color: '#757575', marginTop: '8px', cursor: 'pointer' }} onClick={(e) => {
                                  setIsOpenCopyCustomer(true);
                                }} />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip> : null}
                      </Box>
                    </Box>
                    <Box>
                      <Box className="title">Shipping address</Box>
                      {orderShippingAddress == null ? <Box>
                        <Box className="item">No shipping address provided </Box>
                      </Box> : <Box sx={{ display: "flex" }}>
                        <Box sx={{ flex: '1' }}>
                          <Box className="item" sx={{ "&>div": { display: "flex" } }}>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("name")} arrow>
                                <Box>{orderShippingAddress.firstName + " " + orderShippingAddress.lastName}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("company")} arrow>
                                <Box>{orderShippingAddress.company}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("address1")} arrow>
                                <Box>{orderShippingAddress.address1}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("address2")} arrow>
                                <Box>{orderShippingAddress.address2}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box sx={{ gap: "20px" }}>
                              <ButtonTooltip title={getButtonTooltipLayout("city")} arrow>
                                <Box>{orderShippingAddress.city}</Box>
                              </ButtonTooltip>
                              <ButtonTooltip title={getButtonTooltipLayout("province")} arrow>
                                <Box>{orderShippingAddress.province}</Box>
                              </ButtonTooltip>
                              <ButtonTooltip title={getButtonTooltipLayout("zipcode")} arrow>
                                <Box>{orderShippingAddress.zipcode}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("country")} arrow>
                                <Box>{orderShippingAddress.country}</Box>
                              </ButtonTooltip>
                            </Box>
                            <Box>
                              <ButtonTooltip title={getButtonTooltipLayout("phone")} arrow>
                                <Box>{orderShippingAddress.phone}</Box>
                              </ButtonTooltip>
                            </Box>
                          </Box>
                        </Box>
                        <Tooltip title={<Box sx={{ fontSize: '14px' }}>copied</Box>} arrow
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              fontSize: '14px'
                            },
                            "& .MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementBottom": {
                              fontSize: '14px'
                            },
                            fontSize: '14px'
                          }}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => { setIsOpenCopyShippingAddress(false) }}
                          open={isOpenCopyShippingAddress}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener>
                          <Box sx={{ height: '30px' }}>
                            <CopyToClipboard text={orderShippingAddress.firstName + " " + orderShippingAddress.lastName + ", " + orderShippingAddress.company + ", " + orderShippingAddress.city + ", " + orderShippingAddress.country + ", " + orderShippingAddress.phone}>
                              <ContentCopyOutlinedIcon sx={{ width: '24px', height: '24px', color: '#757575', marginTop: '8px', cursor: 'pointer' }} onClick={(e) => {
                                e.stopPropagation();
                                setIsOpenCopyShippingAddress(true);
                              }} />
                            </CopyToClipboard>
                          </Box>
                        </Tooltip>
                      </Box>}

                    </Box>
                    <Box sx={{ border: 'none!important' }}>
                      <Box className="title">Billing address</Box>
                      {billingAddressMatchesShippingAddress ? <Box className="item" sx={{ color: '(51, 51, 51, 0.70)' }}>Same as shipping address</Box> :
                        (orderBillingAddress == null ? <Box>
                          <Box className="item">No billing address provided</Box>
                        </Box> : <Box sx={{ display: "flex" }}>
                          <Box sx={{ flex: '1' }}>
                            <Box className="item" sx={{ "&>div": { display: "flex" } }}>
                              <Box>
                                <ButtonTooltip title={getButtonTooltipLayout("name")} arrow>
                                  <Box>{orderBillingAddress.firstName + " " + orderBillingAddress.lastName}</Box>
                                </ButtonTooltip>
                              </Box>
                              <Box>
                                <ButtonTooltip title={getButtonTooltipLayout("company")} arrow>
                                  <Box>{orderBillingAddress.company}</Box>
                                </ButtonTooltip>
                              </Box>
                              <Box sx={{ gap: "20px" }}>
                                <ButtonTooltip title={getButtonTooltipLayout("city")} arrow>
                                  <Box>{orderBillingAddress.city}</Box>
                                </ButtonTooltip>
                                <ButtonTooltip title={getButtonTooltipLayout("province")} arrow>
                                  <Box>{orderBillingAddress.province}</Box>
                                </ButtonTooltip>
                                <ButtonTooltip title={getButtonTooltipLayout("zipcode")} arrow>
                                  <Box>{orderBillingAddress.zipcode}</Box>
                                </ButtonTooltip>
                              </Box>
                              <Box>
                                <ButtonTooltip title={getButtonTooltipLayout("country")} arrow>
                                  <Box>{orderBillingAddress.country}</Box>
                                </ButtonTooltip>
                              </Box>
                              <Box>
                                <ButtonTooltip title={getButtonTooltipLayout("phone")} arrow>
                                  <Box>{orderBillingAddress.phone}</Box>
                                </ButtonTooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Tooltip title={<Box sx={{ fontSize: '14px' }}>copied</Box>} arrow
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                fontSize: '14px'
                              },
                              "& .MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementBottom": {
                                fontSize: '14px'
                              },
                              fontSize: '14px'
                            }}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => { setIsOpenCopyBillingAddress(false) }}
                            open={isOpenCopyBillingAddress}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener>
                            <Box sx={{ height: '30px' }}>
                              <CopyToClipboard text={orderBillingAddress.firstName + " " + orderBillingAddress.lastName + ", " + orderBillingAddress.company + ", " + orderBillingAddress.city + ", " + orderBillingAddress.country + ", " + orderBillingAddress.phone}>
                                <ContentCopyOutlinedIcon sx={{ width: '24px', height: '24px', color: '#757575', marginTop: '8px', cursor: 'pointer' }} onClick={(e) => {
                                  e.stopPropagation();
                                  setIsOpenCopyBillingAddress(true);
                                }} />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        </Box>)
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>}
          </Box>
        </Box>
      </Box >
    </>
  );
}

function TransactionTrackingURLAndTrackingNumberInput(props) {
  const {
    index,
    trackingNumber,
    trackingURL,
    summaryTrackingURLList,
    summaryTrackingNumberList,
    setSummaryTrackingURLList,
    setSummaryTrackingNumberList,
    refetchPage,
    onClose,
    deleteTimes
  } = props;
  const [trackingNumberInput, setTrackingNumberInput] = useState(trackingNumber != null && trackingNumber != "" ? trackingNumber : "");
  const [trackingURLInput, setTrackingURLInput] = useState(trackingURL != null && trackingURL != "" ? trackingURL : "");
  useEffect(() => {
    setTrackingNumberInput(trackingNumber != null && trackingNumber != "" ? trackingNumber : "");
    setTrackingURLInput(trackingURL != null && trackingURL != "" ? trackingURL : "");
  }, [deleteTimes])
  return (
    <Box sx={{
      background: index % 2 == 0 ? "#FFF" : "#F0F0F0",
      padding: '0px 24px',
    }}>
      <Box sx={{
        display: "flex",
        gap: "16px"
      }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "10px", paddingTop: "10px" }}>
            <Box sx={{ width: "200px", fontWeight: '700', }}>Tracking Number:</Box>
            <Box sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: '4px'
            }}>
              <TextField
                sx={{
                  paddingRight: "0",
                  backgroundColor: "#fff",
                  border: 'none',
                  fontSize: "14px",
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: "24px",
                  letterSpacing: '0.15px',
                  color: "rgba(0, 0, 0, 0.6)",
                  borderColor: "transparent",
                  borderRadius: '10px',
                  width: "300px",
                  "& fieldset": {
                    borderColor: "transparent!important",
                    borderWidth: '1px',
                    "&.Mui-focused": {
                      borderColor: "transparent!important",
                      borderWidth: '1px',
                    },
                  },
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    padding: "0px",
                    fontSize: '14px'
                  },
                  "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                    padding: "6px 10px",
                    fontSize: '14px'
                  }
                }}
                id="outlined-multiline-static-Tracking-Number"
                placeholder="Enter order Tracking Number"
                value={trackingNumberInput}
                onChange={(e) => {
                  setTrackingNumberInput(e.target.value);
                }}
                onBlur={(e) => {
                  const result = summaryTrackingNumberList.map((item, trackingNumberIndex) => {
                    if (trackingNumberIndex == index) {
                      return e.target.value;
                    }
                    return item;
                  })
                  setSummaryTrackingNumberList(result);
                  refetchPage();
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: 'center', paddingBottom: "10px", paddingTop: "10px" }}>
            <Box sx={{ width: "200px", fontWeight: '700', }}>Tracking URL:</Box>
            <Box sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: '4px'
            }}>
              <TextField
                sx={{
                  paddingRight: "0",
                  backgroundColor: "#fff",
                  border: 'none',
                  fontSize: "14px",
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: "24px",
                  letterSpacing: '0.15px',
                  color: "rgba(0, 0, 0, 0.6)",
                  borderColor: "transparent",
                  borderRadius: '10px',
                  width: "300px",
                  "& fieldset": {
                    borderColor: "transparent!important",
                    borderWidth: '1px',
                    "&.Mui-focused": {
                      borderColor: "transparent!important",
                      borderWidth: '1px',
                    },
                  },
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    padding: "0px",
                    fontSize: '14px'
                  },
                  "& .MuiInputBase-input.MuiOutlinedInput-input ": {
                    padding: "6px 10px",
                    fontSize: '14px'
                  }
                }}
                id="outlined-multiline-static-trackingURL"
                placeholder="Enter order trackingURL"
                value={trackingURLInput}
                onChange={(e) => {
                  setTrackingURLInput(e.target.value);
                }}
                onBlur={(e) => {
                  const result = summaryTrackingURLList.map((item, trackingURLIndex) => {
                    if (trackingURLIndex == index) {
                      return e.target.value;
                    }
                    return item;
                  })
                  setSummaryTrackingURLList(result);
                  refetchPage();
                }}
              />
            </Box>
          </Box>
        </Box>
        <DeleteIcon sx={{ fontSize: "24px", marginLeft: "50px", color: "rgba(0,0,0,0.5)", cursor: "pointer", "&:hover": { color: "#000" } }} onClick={onClose} />
      </Box>
    </Box>
  )
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}